import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addEntreprise = ( entreprise ) => {
  return axios.post(API_URL + "/auth/" , entreprise ,{ headers: authHeader() });
};

const loginEntreprise = ( entreprise ) => {
  return axios.post(API_URL + "/auth/login" , entreprise ,{ headers: authHeader() });
};

const getComptableEntreprises = () => {
  return axios.get(API_URL + "/entreprise/comptable" ,{ headers: authHeader() });
}

const getEntreprises = () => {
  return axios.get(API_URL + "/entreprise/all" ,{ headers: authHeader() });
}

const addNewEntreprise = ( entreprise ) => {
  return axios.post(API_URL + "/entreprise/" , entreprise , { headers: authHeader() });

}

const addAffectation = ( id , affectation  ) => {
  return axios.post(API_URL + "/entreprise/affectation/" + id , affectation , { headers: authHeader() });

}
const getEntreprise = ( ) => {
  return axios.get(API_URL + "/entreprise/" ,{ headers: authHeader() });
};

const updateEntreprise = (id , entreprise ) => {
  return axios.put(API_URL + "/entreprise/" + id , entreprise , { headers: authHeader() });
};

const checkUserEntreprise = (id)=> {
  return axios.get(API_URL + "/entreprise/user/" + id , { headers: authHeader() });  

}


const getEntreprisePersonnel = (id)=> {
  return axios.get(API_URL + "/entreprise/personnel/" + id , { headers: authHeader() });  

}




const exportsMethodes = {
  addEntreprise,
  loginEntreprise,
  getEntreprise,
  addNewEntreprise,
  updateEntreprise,
  addAffectation,
  checkUserEntreprise,
  getEntreprisePersonnel,
  getEntreprises,
  getComptableEntreprises
};
export default exportsMethodes;