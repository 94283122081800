import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Layout, message } from "antd";
import "antd-country-phone-input/dist/index.css";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import "./assets/App.css";
import "./assets/purple-antd.css";
import DashboardFactureSimple from "./componets/pages/direct/DashboardFactureSimple";
import FactureSimple from "./componets/pages/direct/FactureSimple";
import Client from "./componets/pages/General/Client";
import ClientsComptable from "./componets/pages/General/ClientsComptable";
import Home from "./componets/pages/Home";
import Inscription from "./componets/pages/Inscription";
import Login from "./componets/pages/Login";
import NotFound from "./componets/pages/NotFound";
import Parametres from "./componets/pages/parametres/Parametres";
import Entreprise from "./componets/pages/parametres/tabs/Entreprise";
import TaxeAndTva from "./componets/pages/parametres/tabs/TaxeAndTva";
import UsersRoles from "./componets/pages/user/UsersRoles";
import DashboardComptbale from "./componets/pages/vente/DashboardComptable";
import DashboardDevis from "./componets/pages/vente/DashboardDevis";
import DashboardFactureVente from "./componets/pages/vente/DashboardFactureVente";
import DashboardNoteCredit from "./componets/pages/vente/DashboardNoteCredit";
import Devis from "./componets/pages/vente/Devis";
import FactureVente from "./componets/pages/vente/FactureVente";
import AppFooter from "./componets/uiHelpers/AppFooter";
import SideNavigation from "./componets/uiHelpers/SideNavigation";
import ThemeSelector from "./componets/uiHelpers/ThemeSelector";
import TopNavigation from "./componets/uiHelpers/TopNavigation";
import { environment } from "./env/config.env";
import { history } from "./helpers/history";
import checkAuth from "./services/check-auth.service";


const { Content, Sider } = Layout;
const API_URL = environment.api;

function App() {
  const [isConnected, setConnection] = useState(true);
  const [userType, setUserType] = useState(true);

  useEffect(() => {
    checkStorage();
  }, []);

  const checkStorage = () => {

    if (localStorage.getItem("user")) {
      const token = { token: localStorage.getItem("user") };
      setUserType(localStorage.getItem("Identity"));
      setConnection(true);
      checkValidity(token);
    } else {
      setConnection(false);
      history.push("/login");
    }
  };

  const checkValidity = async (token) => {
    const response = await checkAuth.checkToken(token);
    if (response.status === 200) checkVerifResult(response.data);
    else message.error("Une erreur est survenue ! ");
  };

  const setUserData = (user) => {
    localStorage.setItem("user", user.token);
    localStorage.setItem("userName", user.USER.nom_prenom);
    localStorage.setItem("Identity", user.USER.type);
    setConnection(true);
    setTimeout(() => {
      window.location.reload(false);
    }, 200);
  };

  const checkVerifResult = (result) => {
    if (!result) {
      localStorage.removeItem("user");
      localStorage.removeItem("permissions");
      setConnection(false);
      history.push("/login");
    }
  };

  // 0:admin
  // 1:comptable
  // 2:user
  return (
    <ThemeSelector>
        <Router history={history}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.11.338/build/pdf.worker.min.js">
            <Switch>
              {isConnected ? (
                <>
                  <Layout style={{ minHeight: "100vh" }} hasSider>
                    <Sider collapsible>
                      <SideNavigation></SideNavigation>
                    </Sider>
                    <Layout className="">
                      <TopNavigation></TopNavigation>
                      <Content style={{ margin: "0 16px" }}>
                        <div
                          className=""
                          style={{ padding: 24, minHeight: 360 }}
                        >
                          <Switch>
                            <Route path="/" exact component={Home} />
                            <Route path="/parametres" component={Parametres} />
                            <Route path="/tva" component={TaxeAndTva} />
                            <Route path="/societe" component={Entreprise} />
                            <Route path="/users" component={UsersRoles} />
                            <Route path="/devis_list" component={DashboardDevis} />
                            <Route path="/client" component={Client} />
                            <Route path="/devis" component={Devis} />
                            {/* <Route path="/v/note_credit" component={DashboardNoteCredit} /> */}
                            <Route path="/factureVente" component={FactureVente} />
                            <Route path="/facture_vente" component={DashboardFactureVente} />
                            <Route path="/s/factures" component={DashboardFactureSimple} />
                            <Route path="/s/facture" component={FactureSimple} />
                            <Route path="/v/comptable_client" component={ClientsComptable} />
                            <Route path="/v/client_factures" component={DashboardComptbale} />
                          </Switch>
                        </div>
                      </Content>
                      <AppFooter></AppFooter>
                    </Layout>
                  </Layout>
                </>
              ) : (
                <>
                  <Route path="/inscription" component={Inscription} />
                  <Route path="/login">
                    <Login onLoginSuccess={(user) => setUserData(user)} />
                  </Route>
                </>
              )}
              <Route path="/404" component={NotFound} />
              <Redirect from="*" to="/404" />
            </Switch>
          </Worker>
        </Router>
    </ThemeSelector>
  );
}
export default App;
