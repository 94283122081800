import { DownloadOutlined, EditOutlined, FilePptOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Card, Col, Dropdown, Form, Input, Menu, message, Modal, Row, Select, Spin, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import { priceFormater } from "../../../helpers/priceFormater";
import PdfViewerModal from "../../../PdfViewerModal";
import DevisService from "../../../services/devis.service";
import entrepriseService from "../../../services/entreprise.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";
  
  const url = environment.serverUrl;
  
  const DashboardDevis = () => {
    const [emailForm] = Form.useForm();

    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [dataTable, setDataTable] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [reference,setReference] = useState();
    const [isSimpleUser , setIsSimpleUser] = useState(false);
    const [entreprises , setEntreprises ] = useState([]);

    const [selectedFacture, setSelectedFacture] = useState();
    const [currentEntreprise , setCurrentEntreprise] = useState();
    const [emailModal, setEmailModal] = useState(false);
    const [userType, setUserType] = useState(true);

    const DEFAULT_EMAIL_DESCRIPTION = "Cher client, Veuillez trouvait ci-joint le devis"
    
    useEffect(() => {
      getEntrepriseInfo();
      getUserIdentity();
      checkUserIdentity();
      return () => {
        clearStates();
      };
    }, []);
  

    const getUserIdentity = ()=> {
      setUserType(Number(localStorage.getItem('Identity')))
    }

    const clearStates = () => {
      setDataTable([]);
      setEditMode(false);
      setUpdateId(null);
    };

    const checkUserIdentity = async () => {
      const identity = localStorage.getItem("Identity");
      if( identity == 2 ) {
        setIsSimpleUser(true);
        loadEntrepriseDevis();
      }
    }

    const loadEntrepriseDevis = async () => {
      setLoading(true);
      const response = await DevisService.getAllDevis();
      if (response.status === 200 || 204) initTableData(response.data)
      else message.error("Une erreur est survenue ! ");
      setLoading(false);
    }

    const initTableData = ( data ) => {
      const list = [];
      for (const element of data) {
        element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
        element.showClient = element.client.raison_social ? element.client.raison_social :  element.client.nom + " " + element.client.prenom;
        element.montant_total = priceFormater(element.montant_total)
        list.push(element);
      }
      setDataTable(list);
    };

  
    const formatteEtat = (etat , bnStatus) => {
      switch( etat ){
        case 0 : return "En cours";
        case 1 : {
          if( bnStatus ) return "Validé";
          else return "Validé";
        }
        case -1 : return "Annulé";
        default : return ""
      }
    }
  
  
    const changeDevisStatus = async (id ,etat) => {
      const response = await DevisService.changeDevisStatus(id,etat);
      if (response.status === 200 || 204) {
        const msg = etat === 1 ? "Validé" : "desactivé" 
         message.info("Devis " + msg );
      } else message.error("Une erreur est survenue ! ");

      if( currentEntreprise ) entrepriseChanged(currentEntreprise);
      else loadEntrepriseDevis();

    }

    const getEntrepriseInfo = async () => {
      setLoading(true);
      const response = await entrepriseService.getEntreprise();
      if (response.data) {
        setEntreprises(response.data);
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
  
    const navigate = (location) => {
      history.push(location);
    };
    
  
    const navigateToUpdate = (record) => {
      const id = record.id;
      history.push("/devis", {id});
    }
    const BonCommandes = [
      {
        title: "Numéro Devis",
        dataIndex: "reference",
        sorter: (a, b) => a?.reference?.localeCompare(b?.reference),
      },
      {
        title: "Date",
        dataIndex: "showDate",
        sorter: (a, b) => a?.showDate?.localeCompare(b?.showDate),
      },
      {
        title: "Client",
        dataIndex: "showClient",
        sorter: (a, b) => a?.showClient?.localeCompare(b?.showClient),
      },
      
      {
        title: "Montant TTC",
        dataIndex: "montant_total",
        render: (text, record) => <b>{Number(record.montant_total).toFixed(2)} TND</b>,
        sorter: (a, b) => a?.montant_total - b?.montant_total,
      },{
        title: "Devise",
        dataIndex: "devise",
        sorter: (a, b) =>a?.devise?.localeCompare(b?.devise),
      },{
        title: "Etat",
        dataIndex: "etat",
        render: (text, record) => <Dropdown.Button overlay={
          <Menu disabled={ record.bn_status || record.etat === 1 }>
            <Menu.Item  disabled={record.etat === 1} className="px-5" key="1" onClick={()=> changeDevisStatus(record.id ,1)}>Validé</Menu.Item>
            <Menu.Item  disabled={record.etat === -1} className="px-5" key="2" onClick={()=> changeDevisStatus(record.id ,-1)}>Annulé</Menu.Item>
          </Menu>
        }>{formatteEtat(record.etat , record.bn_status)}</Dropdown.Button>
      },
      {
        title: "Action",
        key: "action",
        width : "15%",
        render: (text, record) => (
          <div>
            <Tooltip title="Visualiser">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => showPDFModal(record)}
                icon={<FilePptOutlined />}
              />
            </Tooltip>
            <Tooltip title="Envoyer par email">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => openSendEmailModal(record , "Devis")}
                icon={<SendOutlined />}
              />
            </Tooltip>
            <Tooltip title="Mettre à jour">
              <Button
                className="mx-1"
                type="dashed"
                disabled={record.etat === 1}
                shape="circle"
                onClick={() => navigateToUpdate(record)}
                icon={<EditOutlined />}

              />
            </Tooltip>
          </div>
        ),
      },
    ];
    const showPDFModal = (record) => {
      setShowModal(true);
      const ref = "Files/Vente/Devis/E-"+record.entrepriseId + "/" + record.reference + ".pdf"
      setReference(ref)
    } 
    const downloadFile = () => {
      window.open(url + reference, 'download');
    }

    const entrepriseChanged = async (e) =>{
      setLoading(true);
      setCurrentEntreprise(e);
      const response = await DevisService.getAllDevisByEntrepise(e);
      if (response.status === 200 || 204) initTableData(response.data)
      else message.error("Une erreur est survenue ! ");
      setLoading(false);
    }

    const sendEmail = async () => {
      setLoading(true);
      const email = emailForm.getFieldsValue();
      email.devisId = selectedFacture;
      const response = await DevisService.sendMail(email);
      if (response.data) message.success("Email envoyer avec succès");
      else message.error("Une erreur est survenue ! ");
      setLoading(false);
      handleCancelEmailModal();
    };

    const openSendEmailModal = (record , type) => {
      const objet = type + " " + record.reference;
      const destinataire = record.client.email;
      emailForm.setFieldsValue({ objet,destinataire });
      setSelectedFacture(record.id);
      setEmailModal(true);
    };
  
    const handleCancelEmailModal = () => {
      emailForm.resetFields();
      setSelectedFacture(null);
      setEmailModal(false);
    };
    
  

    return (
      <>
      { userType === 2 ? (

        <Spin spinning={isLoading} size="large">
          <Card
            title={"Devis"}
            extra={
              isSimpleUser ?
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={() => navigate("/devis")}
              >
                Ajouter Un Devis
              </Button> :
              <Select placeholder="Entreprise" onChange={(e) => entrepriseChanged(e)} style={{width: "250px"}} >
                {entreprises &&
                  entreprises.map((entreprise) => (
                    <Select.Option
                      key={entreprise.id}
                      value={entreprise.id}
                    >
                      {entreprise.raison_social} 
                    </Select.Option>
                  ))}
              </Select>
            }
          >
            <TableWithFiltres data={dataTable} columns={BonCommandes} />
          </Card>
          <Modal
            title={
              <>
                <div className="d-flex justify-content-between ">
                  <div className="my-0">
                    Devis 
                  </div>
                  <div className="px-5 ">
                    <Button  onClick={()=> downloadFile()} icon={<DownloadOutlined />} >
                      Telecharger
                    </Button>
                  </div>
                </div>
              </>
            }
            centered
            visible={showModal}
            footer={null}
            width={1000}
            onCancel={()=> setShowModal(false)}
          >
            { showModal && 
              <div className="heigt-500px">
                <PdfViewerModal file={reference}></PdfViewerModal>
              </div>
            }
          </Modal>
          <Modal
            visible={emailModal}
            getContainer={false}
            centered
            onOk={emailForm.submit}
            okText={"Valider"}
            cancelText={"Annuler"}
            onCancel={handleCancelEmailModal}
            title={"Email"}
            width={800}
          >
            <Form layout="vertical" form={emailForm} onFinish={sendEmail} initialValues={{ ["description"]: DEFAULT_EMAIL_DESCRIPTION }} >

              <Row>
                <Col className="px-2" span={12}>
                  <Form.Item
                    label="Objet"
                    name="objet"
                    rules={[
                      {
                        required: true,
                        message: "Champ obligatoire !",
                      },
                    ]}
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
                <Col className="px-2" span={12}>
                  <Form.Item
                    label="E-mail du destinataire"
                    name="destinataire"
                    rules={[
                      {
                        type: "email",
                        message: "Format invalid!",
                      },
                      {
                        required: true,
                        message: "champ obligatoire !",
                      },
                    ]}
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
                <Col className="px-2" span={24}>
                  <Form.Item
                    label="Liste des e-mails en copie"
                    name="cc"
                    extra="Les E-mails doivent être séparées par une seule virgule. Exemple : azery@test.com,querty@test.com"
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
                <Col className="px-2" span={24}>
                  <Form.Item label="Corps de l'email" name="description" rules={[]}>
                    <TextArea type="text" />
                  </Form.Item>
                </Col>

              </Row>
            </Form>
          </Modal>
        </Spin>
        ) : (
        <NotAuthorized></NotAuthorized>
      )}
      </>
    );
  };
  export default DashboardDevis;
  