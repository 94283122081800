import {
  CopyOutlined, DeleteOutlined, DollarOutlined, DownloadOutlined,
  EditOutlined, ExclamationCircleOutlined, FilePptOutlined, QuestionCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Empty,
  Form,
  InputNumber,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Tag,
  Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import PdfViewerModal from "../../../PdfViewerModal";
import ClientService from "../../../services/client.service";
import CompteBancaireService from "../../../services/compteBancaire.service";
import FactureService from "../../../services/factureVente.service";
import PaiementVenteService from "../../../services/paiement.service";
import TableWithFiltres from "../../uiHelpers/TableFilters";
const url = environment.serverUrl;

const DashboardFactureSimple = () => {
  const [paiementForm] = Form.useForm();
  const [comptesBancaires ,setComptesBancaires] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [reference, setReference] = useState();
  const [factureInfo,setFactureInfo] = useState({});
  const [paiementsModal, setPaiementsModal] = useState(false);
  const [paiementsList, setPaiementsList] = useState([]);
  const [compteInput,setCompteInput] = useState(false);



  useEffect(() => {
    loadFactures();
    loadComptesBancaires();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadFactures = async () => {
    setLoading(true);
    await FactureService.getFactures(false, async (response) => {
      if (response.status === 200 || 204) {
        const data = response.data;
        await ClientService.getClientsNames((resp) => {
          if (response.status === 200 || 204) {
            initTableData(data, resp.data);
          } else message.error("Une erreur est survenue ! ");
        });
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    });
  };
  

  const initTableData = (data, clients) => {
    const showMode = localStorage.getItem("SHOW_MODE");
    const list = [];
    for (const element of data) {
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
      const clientId = element.produit_facture_ventes[0]?.clientId;
      element.clientId = clientId;
      element.client = getClientName(clientId, clients);
      element.status = getFactureStatus(element.paiement_status);
      if(showMode === "NO") {
        if(element.etat !== -1) list.push(element);
      } else list.push(element);
    }
    setDataTable(list);
  };
  
  const getFactureStatus = (status) =>{
    switch (status) {
      case  1:
        return "Payé";
      case  2:
        return "Payé Partiellement";
      default:
        return "Non Payé";
    }
  };

  const loadComptesBancaires = async () => {
    setLoading(true);
    const response = await CompteBancaireService.getCompteBancaires();
    if (response.status === 200 || 204) setComptesBancaires(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };
  const getClientName = (id, list) => {
    for (const element of list) {
      if (element.id === id) return element.nom + " " + element.prenom;
    }
  };

  const formatteEtat = (etat) => {
    switch (etat) {
      case 0:
        return "En cours";
      case 1:
        return "Validé";
      case -1:
        return "Annulé";
      default:
        return "";
    }
  };

  const changeDevisStatus = async (id, etat) => {
    const response = await FactureService.changeEtatSimple(id, etat);
    if (response.status === 200 || 204) {
      const msg = etat === 1 ? "Validé" : "Desactivé";
      message.info("Facture " + msg);
    } else message.error("Une erreur est survenue ! ");
    loadFactures();
  };

  const navigate = (location) => {
    history.push(location);
  };

  const navigateToUpdate = (record) => {
    const id = record.id;
    history.push("/s/facture", { id });
  };
  const loadFacturePaiements = async (id) => {
    const response = await PaiementVenteService.getActivePaiements(id);
    if (response.status === 200 || 204){
      const data = response.data;
      var somme_paiement= 0
      for (const element of data) {
        const nbr = element.id+""
        element.moyenPaiement = element.moyen_paiement ? element.moyen_paiement : "Non mentionné"
        const prefix =  element.moyenPaiement === "Non mentionné"  ? "AC-" : "P-"
        element.reference = prefix + nbr.padStart(4, '0');
        element.showDate = moment(element.date).format("YYYY/MM/DD");
        if(element.date_encaissement){
          element.showEncaissementDate = moment(element.date_encaissement).format("YYYY/MM/DD");
        }else{
          element.showEncaissementDate = element.showDate
        }
        somme_paiement += element.montant;
      }
      setPaiementsList(data);
      return somme_paiement;
    } 
    else message.error("Une erreur est survenue ! ");
  };

  const openPaiementModal = async (record) => {
    setLoading(true);
    const somme = await loadFacturePaiements(record.id);
    const rest = record.montant_total - somme;
    setFactureInfo({
      id : record.id, 
      reference : record.reference, 
      montant : record.montant_total, 
      rest : rest.toFixed(2), 
      somme : somme.toFixed(2),
      etat : record.etat,
      devise : record.devise
    });
    setPaiementsModal(true);
    setLoading(false);
  };


  const closePaiementModal = () => {
    setPaiementsModal(false);
    paiementForm.resetFields();
  }
  const addPaiement = async (values) => {
    const fullyPaied = Number(values.montant) === Number(factureInfo.rest) ? 1 : 2;  
    const data = values;
    data.factureVenteId = factureInfo.id;
    data.etat = 1;
    data.fullyPaied = fullyPaied;
    const response = await PaiementVenteService.addPaiement(data);
    if( response.status === 200 ){
      setPaiementsModal(false);
      loadFactures();
      paiementForm.resetFields();
      message.info("Paiement ajouter succès" );
    } else message.error("Une erreur est survenue ! ");   
    setLoading(false)
  };

  const deletePaiement = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce paiement ?",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };

  const deleteItem = async (item) => {
    const response = await PaiementVenteService.deletePaiement(item.id);
    if (response.status === 204 || 200) {
      await loadFacturePaiements(factureInfo.id);
      closePaiementModal();
      message.info("Suppression succès");
    } else message.error("Une erreur est survenue ! ");
  };

  const locale = {
    emptyText: (
      <Empty description={"Le tableau est vide pour le moment "}></Empty>
    ),
  };
  const paiementsCols = [
    {
      title: "Référence",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Moyen de paiement",
      dataIndex: "moyenPaiement",
      sorter: (a, b) => a.moyenPaiement.localeCompare(b.moyenPaiement),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Date d'encaissement",
      dataIndex: "showEncaissementDate",
      sorter: (a, b) => a.showEncaissementDate.localeCompare(b.showEncaissementDate),
    },
    {
      title: "Montant TTC",
      dataIndex: "montant",
      render: (text, record) => (
        <b>{Number(record.montant).toFixed(2)} {factureInfo.devise}</b>
      ),
      sorter: (a, b) => a.montant - b.montant,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"

              onClick={() => deletePaiement(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];


  const facturesCols = [
    {
      title: "Référence",
      dataIndex: "reference",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },

    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(2)} TND</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },{
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) =>a.devise.localeCompare(b.devise),
    },{
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => (
        <Dropdown.Button
          overlay={
            <Menu>
              <Menu.Item
                disabled={record.etat === 1}
                className="px-5"
                key="1"
                onClick={() => changeDevisStatus(record.id, 1)}
              >
                Validé
              </Menu.Item>
              <Menu.Item
                disabled={record.etat === -1}
                className="px-5"
                key="2"
                onClick={() => changeDevisStatus(record.id, -1)}
              >
                Annulé
              </Menu.Item>
            </Menu>
          }
        >
          {formatteEtat(record.etat)}
        </Dropdown.Button>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },{
      title: "Etat Paiement",
      dataIndex: "etat_paiement",
      render: (text, record) => (
        <>
          {record.status === "Payé" && 
            <Tag color="#2db7f5">Payé</Tag>
          }
          {record.status === "Payé Partiellement" && 
            <Tag color="#87d068">Payé Partiellement</Tag>
          }
          {record.status === "Non Payé" && 
            <Tag color="#f50">Non Payé</Tag>
          }
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Dupliquer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => confirmDuplication(record)}
              icon={<CopyOutlined />}
            />
          </Tooltip>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => showPDFModal(record)}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              disabled={record.etat === 1 }
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => navigateToUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Ajouter un règlement">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => openPaiementModal(record)}
              icon={<DollarOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const showPDFModal = (record) => {
    setShowModal(true);
    setReference(record.reference);
  };
  const downloadFile = () => {
    window.open(url + "Files/Vente/Facture/" + reference + ".pdf", "download");
  };

  const confirmDuplication = (record) => {
    Modal.confirm({
      icon: <QuestionCircleOutlined  />,
      content: "Êtes-vous sûr de dupliquer cette facture ?",
      okText: "Oui",
      onOk: () => duplicateData(record),
      cancelText: "Non",
    });
  };
  const duplicateData = async (record) => {
    const response = await FactureService.duplicate(record.id);
    if(response.data){
      const id  = response.data.id;
      message.success("Duplication succès");
      history.push("/s/facture", {id});
    }else message.error("Une erreur est survenue ! ");
  }

  const selectChange = () => {
    const paiement = paiementForm.getFieldsValue();
    const moyen_paiement = paiement.moyen_paiement;
    const moyens = ["Carte Bancaire", "Chèque" , "Virement"];
    if( moyens.includes(moyen_paiement)) setCompteInput(true);
    else setCompteInput(false);
  };
  const checkPaiement = () => {
    return factureInfo.etat !== 1 || ( Number(factureInfo.rest) === 0 )
  }

  return (
    <>
      <Spin spinning={isLoading} size="large">
        <Card
          title={"Facture"}
          extra={
            <Button
              className="mx-2"
              shape="round"
              type="primary"

              onClick={() => navigate("/s/facture")}
            >
              Ajouter Une Facture
            </Button>
          }
        >
          <TableWithFiltres data={dataTable} columns={facturesCols} />
        </Card>
        <Modal
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">Facture : {reference}</div>
                <div className="px-5 ">
                  <Button
                    onClick={() => downloadFile()}
                    icon={<DownloadOutlined />}
                  >
                    Telecharger
                  </Button>
                </div>
              </div>
            </>
          }
          centered
          visible={showModal}
          footer={null}
          width={1000}
          onCancel={() => setShowModal(false)}
        >
          {showModal && (
            <div className="heigt-500px">
              <PdfViewerModal
                diractory="Files/Vente/Facture/"
                reference={reference}
              ></PdfViewerModal>
            </div>
          )}
        </Modal>

        

        <Modal
          visible={paiementsModal}
          getContainer={false}
          centered
          onOk={paiementForm.submit}
          onCancel={() =>  closePaiementModal()}
          title={"Paiements"}
          width={900}
        >
          <Row>
            <Col span={6}><b>Facture : {factureInfo.reference} </b></Col>
            <Col span={6}><b>Total TTC : {factureInfo.montant} {factureInfo.devise}</b></Col>
            <Col span={6}><b>Total Payé : {factureInfo.somme} {factureInfo.devise}</b></Col>
            <Col span={6}><b>Reste à payé : {factureInfo.rest} {factureInfo.devise}</b></Col>
          </Row>
          <hr /> 
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Liste des paiements" key="1">
              <Table
              expandable={{
                expandedRowRender: record => <p className="px-1"><b> Description : </b>{record.description }</p>,
                rowExpandable: record => record.description !== null
                }}
                showSorterTooltip={false}
                rowKey={(record) => record.id}
                locale={locale}
                columns={paiementsCols}
                dataSource={paiementsList}
                pagination={{ position: ["bottomCenter"], pageSize: 5 }}
              />


            </Tabs.TabPane>
            <Tabs.TabPane disabled={checkPaiement()} tab="Nouveau paiement" key="2">
              <Form
                layout="vertical"
                form={paiementForm}
                onFinish={addPaiement}
              >
                <Row>
                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Type de paiement"
                      name="moyen_paiement"
                    >
                      <Select size="large" onChange={() => selectChange()}>
                        <Select.Option key="1" value="Espece">
                          Espece
                        </Select.Option>
                        <Select.Option key="2" value="Carte Bancaire">
                          Carte Bancaire
                        </Select.Option>
                        <Select.Option key="3" value="Chèque">
                          Chèque
                        </Select.Option>
                        <Select.Option key="4" value="Virement">
                          Virement
                        </Select.Option>
                        <Select.Option key="5" value="Effet">
                          Effet
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} className="px-2">
                    <Form.Item
                      label="Montant"
                      name="montant"
                      rules={[
                        {
                          required: true,
                          message:
                            "Champ obligatoire !",
                        },
                      ]}
                    >
                      <InputNumber
                        size="large"
                        style={{ width: "100%" }}
                        min={0}
                        max={factureInfo.rest}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} className="px-2">
                    <Form.Item
                      label="Date"
                      name="date"
                      rules={[
                        {
                          required: true,
                          message:"Champ obligatoire !",
                        },
                      ]}
                    >
                      <DatePicker placeholder=" " size="large" className="w-100" format={"DD-MM-YYYY"}></DatePicker>
                    </Form.Item>
                  </Col>
                  { compteInput &&
                    <>
                    <Col span={12} className="px-2">
                    <Form.Item
                      label="Compte Bancaire"
                      name="compteBancaireId"
                    >
                      <Select size="large" onChange={() => selectChange()}>
                        { comptesBancaires && comptesBancaires.map((compte) => (
                          <Select.Option key={compte.id} value={compte.id}>
                            {compte.designation}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} className="px-2">
                    <Form.Item
                      label="Date d'encaissement"
                      name="date_encaissement"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire !",
                        },
                      ]}
                    >
                      <DatePicker format={"DD-MM-YYYY"} placeholder=" " size="large" className="w-100"></DatePicker>
                    </Form.Item>
                  </Col>
                    </>
                  }

                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          message:
                            "Champ obligatoire avec un maximum de caractère 255 !",
                          max: 254,
                        },
                      ]}
                    >
                      <TextArea rows={3} type="text" maxLength={255} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        </Modal>
      </Spin>
    </>
  );
};
export default DashboardFactureSimple;
