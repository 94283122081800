import { Card, Col, Row, Tabs } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import Can from "../../../security/Can";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import Entreprise from "./tabs/Entreprise";
import Taxes from "./tabs/Taxes";
import Tva from "./tabs/Tva";
const { TabPane } = Tabs;

const Parametres = ({ methods, name }) => {
  const changeColor = (color) => {
    localStorage.setItem("COLOR_THEME", color);
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };
  const changeShowMode = (mode) => {
    localStorage.setItem("SHOW_MODE", mode);
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }

  const changeFactureMode = ( mode ) => {
    localStorage.setItem("SHOW_FAC_MODE", mode);
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }

  return (
    
    <Card title={"Paramètres généraux"}>
      <Row>
        <Col span={4} className="px-2">
          <h5>Couleur :</h5>
        </Col>
        <Col span={4} className="px-2">
          <Avatar
            className="pointer"
            onClick={() => changeColor("PURPLE")}
            style={{ color: "#fff", backgroundColor: "#db2dd7" }}
          >
            P
          </Avatar>
        </Col>
        <Col span={4} className="px-2">
          <Avatar
            className="pointer"
            onClick={() => changeColor("BLUE")}
            style={{ color: "#fff", backgroundColor: "#1890ff" }}
          >
            B
          </Avatar>
        </Col>
        <Col span={4} className="px-2">
          <Avatar
            className="pointer"
            onClick={() => changeColor("GREEN")}
            style={{ color: "#fff", backgroundColor: "#66d132" }}
          >
            G
          </Avatar>
        </Col>
        <Col span={4} className="px-2">
          <Avatar
            className="pointer"
            onClick={() => changeColor("YELLOW")}
            style={{ color: "#fff", backgroundColor: "#faad14" }}
          >
            Y
          </Avatar>
        </Col>
        <Col span={4} className="px-2">
          <Avatar
            className="pointer"
            onClick={() => changeColor("DARK")}
            style={{ color: "#fff", backgroundColor: "#000" }}
          >
            D
          </Avatar>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col span={4} className="px-2">
          <h5>Langue :</h5>
        </Col>
        <Col span={4} className="px-2">
          <Avatar className="pointer"> FR </Avatar>
        </Col>
        <Col span={4} className="px-2">
          <Avatar className="pointer"> EN </Avatar>
        </Col>
      </Row>


    </Card>

  );
};
export default Parametres;
