import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import React, { useEffect } from "react";
import { environment } from "./env/config.env";

const url = environment.serverUrl;
function PdfViewerModal({file}) {
    useEffect(()=> {
      return () => {
        clearStates();
        };
    },[file])

    const clearStates = () => {
      file = null;
    }
  return (

      <Viewer fileUrl={url + file} />

  );
}
export default PdfViewerModal;