import { Ability, AbilityBuilder } from "@casl/ability";
const ability = new Ability();

export default (action, subject) => {
  return ability.can(action, subject);
};

const defineRulesFor = (auth) => {
  const permissions = auth;
  const { can, rules } = new AbilityBuilder();
  if (permissions.length !== 0) {
    permissions.forEach((p) => {
      if(p.etat === 1) can(p.perms)
    });
  }
  return rules;
};

if (localStorage.getItem("permissions")) {
  let auth = JSON.parse(localStorage.getItem("permissions"));
  ability.update(defineRulesFor(auth));
}