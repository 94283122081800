import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addFacture = async ( facture ) => {
  return await axios.post(API_URL + "/v/facture" , facture ,{ headers: authHeader() });
};


const getEntrepriseFactures = () => {
  return axios.get(API_URL + "/v/facture/entreprise"  ,{ headers: authHeader() });
}


const getFactureByEntreprise = ( id ) => {
  return axios.get(API_URL + "/v/facture/entreprise/"+id  ,{ headers: authHeader() });
}

const getFactures = async (type, callback ) => {
  callback( await axios.get(API_URL + "/v/facture/type/" + type , { headers: authHeader() }))
};
const getFacture = async ( id, callback ) => {
  callback( await axios.get(API_URL + "/v/facture/" + id , { headers: authHeader() }))
};
const updateFacture = (id , facture ) => {
  return axios.put(API_URL + "/v/facture/" + id , facture, { headers: authHeader() });
};

const deleteFacture = (id) => {
  return axios.delete(API_URL + "/v/facture/" + id , { headers: authHeader() });
};
const changeEtat = (id ,etat) => {
  return axios.post(API_URL + "/v/facture/state/" + id , {etat : etat} , { headers: authHeader() });
};

const changePaymentStatus = (id ,etat) => {
  return axios.post(API_URL + "/v/facture/paiement/state/" + id , {etat : etat} , { headers: authHeader() });
};

const changeEtatSimple = (id , etat) => {
  return axios.post(API_URL + "/v/facture/simple_state/" + id , {etat : etat} , { headers: authHeader() });
};

const getActiveReception = async (id) => {
  return await axios.get(API_URL + "/v/facture/active/" + id ,{ headers: authHeader() });
}

const getLast = async (callback) => {
  callback( await axios.get(API_URL + "/v/facture/last", { headers: authHeader() }))
}

const loadFactureMontants = async () =>{
  return await axios.get(API_URL + "/v/facture/montants/" ,{ headers: authHeader() });
}
const loadByStatus = async () => {
  return await axios.get(API_URL + "/v/facture/byStatus/" ,{ headers: authHeader() });

}
const duplicate = async ( id) => {
  return axios.get(API_URL + "/v/facture/duplicate/" + id , {headers: authHeader()})
}

const getFacturesDataByBnCommande = async (id) => {
  return axios.get(API_URL + "/v/facture/advanced/" + id , {headers: authHeader()})

}

const getFactureByEntrepriseAndYear = (entrepriseId  , year ,month) => {
  return axios.get(API_URL + "/v/facture/search?entreprise= " + entrepriseId +"&year=" + year + '&month=' + month , {headers: authHeader()})
}
const sendMail = (data) => {
  return axios.post(API_URL + "/v/facture/sendMail" , data , {headers: authHeader()})
}

const exportsMethodes = {
  addFacture,
  getFactures,
  getFacture,
  updateFacture,
  deleteFacture,
  changeEtat,
  getActiveReception,
  getLast,
  loadFactureMontants,
  loadByStatus,
  changeEtatSimple,
  duplicate,
  getFacturesDataByBnCommande,
  getEntrepriseFactures,
  getFactureByEntreprise,
  getFactureByEntrepriseAndYear,
  sendMail,
  changePaymentStatus
};
export default exportsMethodes;