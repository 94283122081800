import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col, DatePicker, Divider, Form, InputNumber, message, Radio, Row,
  Select,
  Spin,
  Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import currencys from '../../../helpers/currency';
import { formatterNumber, parserNumber } from "../../../helpers/priceFormater";
import ClientService from "../../../services/client.service";
import DevisService from "../../../services/devis.service";
import EntrepriseService from "../../../services/entreprise.service";
import TaxeService from "../../../services/taxe.service";
import TvaService from "../../../services/tva.service";
import FicheClient from "../../uiHelpers/Fiches/FicheClient";
import FicheDetails from "../../uiHelpers/Fiches/FicheDetails";
import FicheFooter from "../../uiHelpers/Fiches/FicheFooter";
import FicheFournisseur from "../../uiHelpers/Fiches/FicheFournisseur";
import FicheHeader from "../../uiHelpers/Fiches/FicheHeader";
import FicheTableTotaux from "../../uiHelpers/Fiches/FicheTableTotaux";
import writtenNumber from '/node_modules/written-number/lib/index';

writtenNumber.defaults.lang = 'fr';


function Devis() {
  const [productsForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [fournisseur, setFournisseur] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [entreprise, setEntreprise] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isDarkMode, setDarkMode] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [appliedTaxes, setListAppliedTaxes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [writtenTotal,setWrittenTotal] = useState("");
  const [selectedCurrency,setSelectedCurrency] = useState("");
  const [tvas , setTvas] = useState([]);

  const [devisType, setDevisType] = useState(false);

  const nbrFactures = [ 2 , 3 , 4 ];

  const history = useHistory();

  const location = useLocation();

  const [prixTotal, setPrixTotal] = useState({
    montant_total: 0,
    total_ttc: 0,
    total_ht: 0,
    tva: 0,
  });

  const isVente = true;

  useEffect(() => {
    loadClients();
    loadEntreprise();
    loadTaxes();
    getThemeMode();
    setUpdate();
    return () => {
      clearStates();
    };
  }, []);


  const setUpdate = async () => {
    if( location.state?.id ){
      const id = location.state?.id;
      setLoading(true);
      await DevisService.getDevis( id , ( response )=> {
        if (response.status === 200 || 204) {

          const clientId = response.data.clientId;
          const productList = {...response.data }

          productList.date = moment(productList.date);
          productList.client = clientId;
          for (const echance of productList.echance_devis) echance.date = moment(echance.date);            
          productsForm.setFieldsValue(productList);
          productsForm.setFieldsValue({typeDevis: productList.echance_devis.length ? true : false });

          
          
          calculeTotalTaxes();
          setSelectedCurrency(response.data.devise);
          const ids = []
          for (const element of productList.produits_devis) ids.push(element.produitId);


          setSelectedIds(ids);
          setUpdateId(id);
          clientChanged();
          setEditMode(true);
        }else message.error("Une erreur est survenue ! ");
        setLoading(false);
      });
    }else{
      generateReference();
    }
  }

  const generateReference = async () => {
    const year = moment(new Date()).format("YYYY");
    var nbr = "1";
    var reference  = "D"+ year + nbr.padStart(4, '0');
    await DevisService.getLast( ( response )=> {
      if (response.data?.reference) {
          const lastRef = response.data.reference;
          const lastFactureYear =  lastRef.substring(1,5);
          if( year === lastFactureYear ){
            nbr = (Number(lastRef.substring(6, 10)) + 1 ) + "";
            reference = "D" + year + nbr.padStart(4, '0');
          }
      }
      productsForm.setFieldsValue({reference: reference ,date : moment(new Date()) } )
      addProduct();
      setLoading(false);
    });
  }

  const loadTaxes = async () => {
    setLoading(true);
    const response = await TvaService.getTvas();
    if( response.data ) formatteTva(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);

  }

  const formatteTva = ( tva ) => {
    setTvas(tva)
  }

  const addProduct = () => {
    const qte = 1;
    const prixUniteHt = 0;
    const prixUniteTTC = 0
    const tva = 17
    const ht =  0;
    const ttc = 0;
    const remise = 0;
    const product = {
      libelle: "",
      quantite: Number(qte),
      prixHT: Number(prixUniteHt),
      prixTTC: Number(prixUniteTTC),
      tva: Number(tva),
      remise: Number(remise),
      total_prix_HT: Number(ht).toFixed(2),
      total_prix_TTC: Number(ttc).toFixed(2),
    };
    const productList = productsForm.getFieldValue("produits_devis")
      ? productsForm.getFieldValue("produits_devis")
      : [];
    productList.push(product);
    productsForm.setFieldsValue({ produits_devis: productList });
    calculeTotalTaxes();
  };

  const getThemeMode = () => {
    const theme = localStorage.getItem("COLOR_THEME");
    const darkMode = theme === "DARK" ? true : false;
    setDarkMode(darkMode);
  };

  const loadClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      setClients(response.data);
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const reloadClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      setClients(response.data);
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };
  const getClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      return response.data;
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };
  

  const loadEntreprise = async () => {
    setLoading(true);
    const response = await EntrepriseService.getEntreprise();
    if (response.status === 200 || 204) {
      setEntreprise(response.data);
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const clearStates = () => {
    setEditMode(false);
    setUpdateId(null);
  };


  const priceChanged = (index) => {
    const products = productsForm.getFieldValue("produits_devis");
    const product = products[index];
    const taxe = product.tva;
    const quantite = product.quantite;
    const prixHT = Number(product.prixHT - product.prixHT * (product.remise / 100)).toFixed(2);
    const prixTTC = (Number(prixHT) + Number((prixHT / 100) * taxe)).toFixed(2);
    const total_ht = Number(prixHT * quantite).toFixed(2);
    const total_ttc = Number(prixTTC * quantite).toFixed(2);
    products[index].total_prix_TTC = Number(total_ttc).toFixed(2);
    products[index].total_prix_HT = Number(total_ht).toFixed(2);
    productsForm.setFieldsValue({ products });
    calculeTotalTaxes();
  };

  const calculeTotalTaxes = async () => {
    const products = productsForm.getFieldValue("produits_devis");
    const list = [];
    for (const product of products) {
      if (product.tva > 0.1) {
        const base = Number(product.total_prix_HT);
        const montantTaxes = Number((product.total_prix_HT) * (product.tva / 100)).toFixed(2);
        const taxe = {
          tvaPorucentage: product.tva,
          montantTaxes: montantTaxes,
          base: base,
        };
        list.push(taxe);
      }
    }
    setListAppliedTaxes(list);
    calculateTotal();
  };



  const clientChanged = async () => {
    const clientId = productsForm.getFieldValue("clientId");
    const clientList = clients.length ? clients : await getClients();
    const cli =  clientList.find((client) => client.id === clientId);
    setClient( cli );

  };



  const calculateTotal = () => {
    const products = productsForm.getFieldValue("produits_devis");
    const remiseGlobal = productsForm.getFieldValue("remise_global") ? productsForm.getFieldValue("remise_global") : 0;
    let total_ht = 0;
    let tva = 0;
    let total_ttc = 0;
    // bug calcule total 
    for (const product of products) {
      const montantTaxes = ( Number(product.total_prix_HT) ) * (product.tva / 100);
      total_ht += Number(product.total_prix_HT);
      tva += montantTaxes;
    }
    total_ht = Number(total_ht) - ( Number(total_ht) *  (Number(remiseGlobal) / 100));
    tva =  tva - ( Number(tva) *  (Number(remiseGlobal) / 100));
    total_ttc += Number(total_ht) + Number(tva)
    let montant_total = Number(total_ttc).toFixed(2);
    const total = { montant_total, total_ttc, total_ht,tva };
    setPrixTotal(total);

    const final = montant_total.toString().split(".");
    const currencyName = currencys.find(c => c.code === productsForm.getFieldValue("devise"));
    setWrittenTotal("Le compte est arrêté à la somme de " + writtenNumber(final[0]) + " " +currencyName.base + " , " +final[1] + " " + currencyName.sub);

  };

  const typeDevisChanged = () => {
    const type = productsForm.getFieldValue("typeDevis");
    setDevisType(type)
    if ( type ){
      const echellon = [];
      const montant = Number( prixTotal.total_ttc / 2 ).toFixed(3);
      const pourcentage = Number( montant / prixTotal.total_ttc * 100 ).toFixed(2);
      for (let i = 0; i < 2; i++) {
        echellon.push({
         pourcentage: pourcentage,
         montant: montant
        });
      }
      productsForm.setFieldsValue({ echance_devis: echellon, nbrFacture: 2 })
    }else{
      productsForm.setFieldsValue({ echance_devis: [] })

    }
  }


  const addDevis = async () => {
    setLoading(true);
    const product = productsForm.getFieldsValue();
    const devis = {...product , ...prixTotal }
    devis.etat = 0;
    devis.remise_global =  devis.remise_global ? devis.remise_global : 0;

    const response = editMode ? 
      await DevisService.updateDevis(updateId,devis) 
      : await DevisService.addDevis(devis);
    if (response.data) {
      if(editMode) message.info("Mise à jour succès");
      else message.info("Devis ajouté avec succès");
      history.push("/devis_list");
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  }

  const changeCurrency = () => {
    const currency = productsForm.getFieldValue("devise");
    setSelectedCurrency(currency);
  }
  

  const nbrFacturesChanged = () => {
    const nbrFacture = productsForm.getFieldValue("nbrFacture");
    const echellon = [];
    const montant = Number( prixTotal.total_ttc / nbrFacture ).toFixed(3);
    const pourcentage = Number( montant / prixTotal.total_ttc * 100 ).toFixed(2);
    for (let i = 0; i < nbrFacture; i++) {
      echellon.push({
       pourcentage: pourcentage,
       montant: montant
      });
    }
    productsForm.setFieldsValue({ echance_devis: echellon })
  }


  const pourcentageChanged = ( i ) => {
    const form = productsForm.getFieldsValue();
    const nbrFacture = form.nbrFacture - 1;
    const restNbr = (form.echance_devis.length - 1 ) - i;
    let somme = 0;

    for (let index = 0; index <= nbrFacture; index++) {
      console.log(form.echance_devis[index]);
      if( index < i ) {
        somme += parseFloat(form.echance_devis[index].pourcentage);
      } else if ( index === i ) {
        somme += parseFloat(form.echance_devis[index].pourcentage);
        form.echance_devis[index].montant = Number((form.echance_devis[index].pourcentage * prixTotal.montant_total) / 100).toFixed(2);
      }else {
        form.echance_devis[index].pourcentage = Number(( 100 - somme ) / restNbr).toFixed(2); 
        form.echance_devis[index].montant = Number(( prixTotal.montant_total * form.echance_devis[index].pourcentage ) / 100)
      }
    }

    productsForm.setFieldsValue({ echance_devis: form.echance_devis });
  }
  
  const montantChanged = ( i ) => {
    const form = productsForm.getFieldsValue();
    const nbrFacture = form.nbrFacture - 1;
    const restNbr = (form.echance_devis.length - 1 ) - i;
    let somme = 0;

    for (let index = 0; index <= nbrFacture; index++) {
      console.log(form.echance_devis[index]);
      if( index < i ) {
        somme += parseFloat(form.echance_devis[index].montant);
      } else if ( index === i ) {
        somme += parseFloat(form.echance_devis[index].montant);
        form.echance_devis[index].pourcentage = Number((form.echance_devis[index].montant / prixTotal.montant_total) * 100).toFixed(2);
      }else {
        form.echance_devis[index].montant = Number(( prixTotal.montant_total - somme ) / restNbr).toFixed(2);
        form.echance_devis[index].pourcentage = Number((form.echance_devis[index].montant / prixTotal.montant_total) * 100).toFixed(2);
      }
    }

    productsForm.setFieldsValue({ echance_devis: form.echance_devis });

  }


  return (
    <>
      <Spin  className="px-5" spinning={isLoading} size="large" >
        <Card className="w-100 card-shadow"> 
          <Form layout="vertical" initialValues={{ devise: "EUR", nbrFactures :2 }} form={productsForm} onFinish={addDevis}>
            <Row>
              <FicheHeader
                moduleName={"Devis"}
                showClients={isVente}
                entreprise={entreprise}
                clients={clients}
                fournisseurs={fournisseurs}
                selectedClient={() => clientChanged()}
                reloadClients={() => reloadClients()}
              ></FicheHeader>
            </Row>
            <Row>
              {isVente ? (
                <Col span={8} className="px-2 pt-3">
                  <FicheClient client={client}></FicheClient>
                </Col>
              ) : (
                <Col span={8} className="px-2 pt-3">
                  <FicheFournisseur fournisseur={fournisseur}></FicheFournisseur>
                </Col>
              )}
              <Col span={8} offset={8} className="px-2 pt-3">
                <FicheDetails 
                  currencyChanged={ ()=>  changeCurrency()}
                ></FicheDetails>
              </Col>
            </Row>
            <Row>
              <Col span="24" className="px-4">
                <table
                  className={` mb-5 table ${isDarkMode ? "table-dark" : ""} `}
                >
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Libelle</th>
                      <th scope="col">Quantité</th>
                      <th scope="col">Prix Unitaire HT</th>
                      <th scope="col">TVA</th>
                      <th scope="col">Remise</th>
                      <th scope="col">Total HT</th>
                      <th scope="col">Total TTC</th>
                      <th scope="col" className="text-center color">
                        <Tooltip title="Ajouter un produit">
                          <span
                            className="medium pointer"
                            type="primary"
                            onClick={() => addProduct()}
                          >
                            <PlusCircleOutlined />
                          </span>
                        </Tooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <Form.List
                      name="produits_devis"
                      label="List des addresse de livraisons"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <>
                              <tr>
                                <th scope="row" ><span>{index + 1}</span></th>
                                <td style={{ width: "40%" }}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "libelle"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:"Champ obligatoire !",
                                      }
                                    ]}
                                  >
                                    <TextArea style={{ width: "100%" }} rows={2} className="mx-0" type="text" />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "quantite"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: " ",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      style={{ width: 100 }}
                                      onChange={() => priceChanged(index)}
                                      className="w-125px"
                                      min={0}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "prixHT"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:" ",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      onChange={() => priceChanged(index)}
                                      style={{ width: 120 }}
                                      className="w-125px"
                                      min={0}
                                      formatter={(value) => formatterNumber(value)}
                                      parser={(value) => parserNumber(value)}
                                      // formatter={(value) => `${value}`}
                                      // parser={(value) =>
                                      //   value.replace("", "")
                                      // }
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "tva"]}
                                    style={{width: "80px", lineHeight : "30px" ,height: "32px"  }}
                                    rules={[
                                      {
                                        required: true,
                                        message: " ",
                                      },
                                    ]}
                                  >
                                  <Select onChange={() => priceChanged(index)} style={{ lineHeight : "30px" ,height: "32px"  }}>
                                    {tvas &&
                                      tvas.map((tva) => (
                                        <Select.Option
                                          key={tva.id}
                                          value={tva.valeur}
                                        >
                                          {tva.valeur} %
                                        </Select.Option>
                                      ))}
                                  </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "remise"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: " ",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      onChange={() => priceChanged(index)}
                                      min={0}
                                      max={100}
                                      style={{ width: 60 }}
                                      formatter={(value) => `${value}%`}
                                      parser={(value) => value.replace("%", "")}
                                    />
                                  </Form.Item>
                                </td>
                                <th scope="row" className=" w-90px">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "total_prix_HT"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec un maximum de 100 caractères  !",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      className={`w-125px ${
                                        isDarkMode
                                          ? "total-input-dark"
                                          : "total-input-light"
                                      } `}
                                      bordered={false}
                                      style={{ width: 120 }}
                                      min={0}
                                      disabled
                                      formatter={(value) => formatterNumber(value)}
                                      parser={(value) => parserNumber(value)}
                                      // formatter={(value) => `${value}`}
                                      // parser={(value) =>
                                      //   value.replace("", "")
                                      // }
                                    />
                                  </Form.Item>
                                </th>
                                <th scope="row" className="w-90px">
                                  <Form.Item {...restField} name={[name, "total_prix_TTC"]}>
                                    <InputNumber
                                      className={`w-125px ${
                                        isDarkMode
                                          ? "total-input-dark"
                                          : "total-input-light"
                                      } `}
                                      style={{ width: 120 }}
                                      bordered={false}
                                      min={0}
                                      disabled
                                      formatter={(value) => formatterNumber(value)}
                                      parser={(value) => parserNumber(value)}
                                      // formatter={(value) => `${value}`}
                                      // parser={(value) =>
                                      //   value.replace("", "")
                                      // }
                                    />
                                  </Form.Item>
                                </th>
                                <td className="text-center red-flag pt-1 w-90px">
                                  <Tooltip title="Supprimer le produit">
                                    <span
                                      className="medium pointer"
                                      type="primary"
                                      onClick={() => {
                                        remove(name);
                                        calculeTotalTaxes();
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </span>
                                  </Tooltip>
                                </td>
                              </tr>
                            </>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </tbody>
                </table>
              </Col>
              <Col span={5} className="px-4">
                <Col span={24}>
                  <Form.Item
                    label="Facture échelonné ?"
                    name={"typeDevis"}
                    initialValue={false}
                    rules={[
                      {
                        required: true,
                        message: "Champ obligatoire !",
                      }, 
                    ]}
                  >
                    <Radio.Group onChange={() => typeDevisChanged()} disabled={ Number(prixTotal.montant_total) === 0}>
                      <Radio value={true}> Oui </Radio>
                      <Radio value={false}> Non </Radio>
                    </Radio.Group>
                  </Form.Item>  
                </Col>
              </Col>
              
              <Col span={11} className="px-4">
                <Row>
                  { devisType === true && 
                    <>
                      <Col span={24}>
                        <Form.Item
                          label="Nombre des factures"
                          name={"nbrFacture"}
                          rules={[
                            {
                              required: true,
                              message: "Champ obligatoire !",
                            }, 
                          ]}
                        >
                          <Select style={{ lineHeight : "30px" ,height: "32px"  }} onChange={() => nbrFacturesChanged()}>
                            { nbrFactures.map((nbr) => (
                                <Select.Option key={nbr} value={nbr} > {nbr}  </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>  
                      </Col>
                      <Col span={2} className="py-2 text-center">
                        Num
                      </Col>
                      <Col span={7} className="py-2 pr-02 pl-02">
                        Pourcentage
                      </Col>
                      <Col span={7} className="py-2 pl-02 pt-1">
                        Montant TTC
                      </Col>
                      {/* <Col span={7} className="py-2 pl-02 pt-1">
                        Date
                      </Col> */}
                      <Col span={7} className="py-2 pl-02 pt-1">
                        Objet
                      </Col> 
                    </>
                  }
                </Row>

                <Row>
                  <Form.List
                    name="echance_devis"
                    label=""
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <>
                            <Col span={2} className="pr-02 pt-1 text-center">
                              <b> { key + 1 } </b>
                            </Col>
                            <Col span={7} className="pr-02 pl-02">
                              <Form.Item
                                {...restField}
                                name={[name, "pourcentage"]}
                                rules={[
                                  {
                                    required: true,
                                    message:"Champ obligatoire !",
                                  }
                                ]}
                              >
                                <InputNumber
                                  onChange={() => pourcentageChanged(index)}
                                  className="w-125px"
                                  min={0}
                                  showCount
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={7} className="pl-02">
                              <Form.Item
                                {...restField}
                                name={[name, "montant"]}
                                rules={[{
                                  required: true,
                                  message: "Champ obligatoire!",
                                }]}
                              >
                                <InputNumber
                                  onChange={() => montantChanged(index)}
                                  className="w-125px"
                                  min={0}
                                  showCount
                                  style={{ width: "100%" }}
                                  formatter={(value) => formatterNumber(value)}
                                  parser={(value) => parserNumber(value)}
                                />
                              </Form.Item>
                            </Col>

                            {/* <Col span={7} className="pl-02">
                              <Form.Item
                                {...restField}
                                name={[name, "date"]}
                                rules={[{}]}
                              >
                                <DatePicker placeholder=" " className="w-100" format={"DD-MM-YYYY"}></DatePicker>
                              </Form.Item>
                            </Col> */}
                            <Col span={7} className="pl-02">
                              <Form.Item
                                {...restField}
                                name={[name, "objet"]}
                                rules={[{}]}
                              >
                                <TextArea placeholder=" " className="w-100"></TextArea>
                              </Form.Item>
                            </Col> 
                              
                          </>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Row>
              </Col>
              <Col span={8} className="px-4">
                <Form.Item  label="Remise" initialValue={0} name="remise_global" className="my-0 py-1">
                  <InputNumber onChange={() => calculateTotal()} style={{ width: "100%" }}
                    formatter={(value) => `${value}%`} parser={(value) => value.replace("%", "")}
                    min={0} max={100}
                  />
                </Form.Item>
                <FicheTableTotaux
                  selectedCurrency={selectedCurrency}
                  isDarkMode={isDarkMode}
                  prixTotal={prixTotal}
                ></FicheTableTotaux>
              </Col>
              <Col span="8"></Col>
              <Col span="8" className="px-4"></Col> 
            </Row>
            <Divider className="text-capitalize">{writtenTotal}</Divider>


            <FicheFooter entreprise={entreprise}></FicheFooter>

            <Row className="d-flex flex-row-reverse pt-5 pb-2">
              <Button 
                className="mx-2" 
                type="primary"
                onClick={productsForm.submit}
              >
                Enregistrer
              </Button>   
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
}

export default Devis;
