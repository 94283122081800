import {
  DownloadOutlined,
  FilePptOutlined,
  SendOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  Tooltip,
  Tag
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { environment } from "../../../env/config.env";
import { priceFormater } from "../../../helpers/priceFormater";
import PdfViewerModal from "../../../PdfViewerModal";
import DevisService from "../../../services/devis.service";
import entrepriseService from "../../../services/entreprise.service";
import FactureVenteService from "../../../services/factureVente.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";
const url = environment.serverUrl;

// const years = [2021, 2022, 2023];
const months = [
  { value : 0,  name : "Tous" } ,
  { value : "01",  name : "Janvier" } ,
  { value : "02",  name : "Février" } ,
  { value : "03",  name : "Mars" } ,
  { value : "04",  name : "Avril" } ,
  { value : "05",  name : "Mai" } ,
  { value : "06",  name : "Juin" } ,
  { value : "07",  name : "Juillet" } ,
  { value : "08",  name : "Aout" } ,
  { value : "09",  name : "Septembre" } ,
  { value : "10",  name : "Octobre" } ,
  { value : "11",  name : "Novembre" } ,
  { value : "12",  name : "Décembre" } ,
];

const DashboardComptbale = () => {
  const [emailForm] = Form.useForm();
  const [yearsForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [factures, setFactures] = useState([]);
  const [notesCredits, setNotesCredits] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [selectedFacture, setSelectedFacture] = useState();
  const [selectedRef, setSelectedRef] = useState();
  const [entreprises, setEntreprises] = useState([]);
  const [userType, setUserType] = useState(true);
  const [years, setYears] = useState([]);

  useEffect(() => {
    getEntrepriseInfo();
    getUserIdentity();
    getLastThreeYears()
    return () => {
      clearStates();
    };
  }, []);

  const getUserIdentity = ()=> {
    setUserType(Number(localStorage.getItem('Identity')))
  }
  const clearStates = () => {
    setNotesCredits([]);
    setFactures([]);

    yearsForm.resetFields();
  };

  const getEntrepriseInfo = async () => {
    setLoading(true);
    const response = await entrepriseService.getComptableEntreprises();
    if (response.data) {
        formatteEntreprises(response.data);
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };
  const formatteEntreprises = (data) => {
      const list = [];
      for (const element of data) {
          list.push(element.entreprise)
      }
      setEntreprises(list);
  }

  const initTableData = async (data) => {
    const factures = [];
    const notesCredits = [];
    for (const element of data) {
        element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
        element.paiement_status = element.paiement_status ? Number(element.paiement_status)  : -1
        element.showClient = element.client.raison_social
          ? element.client.raison_social
          : element.client.nom + " " + element.client.prenom;
        element.devisRef = await getCommandeReference(element.bonCommandeId);
        element.montant_total = priceFormater(element.montant_total)
        factures.push(element);
    }
    setFactures(factures);
  };

  const getCommandeReference = async (bonCommandeId) => {
    const response = await DevisService.getReference(bonCommandeId);
    return response.data.reference;
  };

  const formatteEtat = (etat, pStatus) => {
    switch (etat) {
      case 0:
        return "En cours";
      case 1:
        if (pStatus) return "Facturé";
        else return "Validé";
      case -1:
        return "Annulé";
      default:
        return "";
    }
  };

  const getLastThreeYears = () => {
    let years = []
    const currentYear = new Date().getFullYear().toString();
    const previous1Year =  (currentYear-1).toString();
    const previous2Year =  (currentYear-2).toString();
    years.push(currentYear,previous1Year,previous2Year)
    setYears(years)
  }

  const showPDFModal = (record , type) => {
    setShowModal(true);
    const ref = (  
       type === "Facture" ? 'Files/Vente/Facture/'  : "Files/Vente/Note_credit/"
      ) + "E-"+record.entrepriseId + "/" + record.reference + ".pdf";

    setSelectedRef({reference : ref , type});
  };

  const downloadFile = () => {

    window.open(url + selectedRef.reference , "download");
  };



  const factureCols = [
    {
      title: "Référence Facture",
      dataIndex: "reference",
      sorter: (a, b) => a?.reference?.localeCompare(b?.reference),
    },
    {
      title: "Référence Devis",
      dataIndex: "devisRef",
      sorter: (a, b) => a?.devisRef?.localeCompare(b?.devisRef),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a?.showDate?.localeCompare(b?.showDate),
      width: "10%",
    },
    {
      title: "Client",
      dataIndex: "showClient",
      sorter: (a, b) => a?.showClient?.localeCompare(b?.showClient),
    },
    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>
          {Number(record.montant_total).toFixed(2)}
        </b>
      ),
      sorter: (a, b) => a?.montant_total - b?.montant_total,
    },
    {
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) => a?.devise.localeCompare(b?.devise),
    },
    
    {
      title: "Etat de Paiement",
      dataIndex: "etat",
      render: (text, record) => (
        <>
          {record.etat == 1 && <Tag color="#2db7f5">Payé</Tag>}
          {record.etat == 0 && <Tag color="#f50">Non Payé</Tag>}
          {record.etat == -1 && <Tag className="text-dark">Annulé</Tag>}
        </>
      ),
      sorter: (a, b) => a?.paiement_status - b?.paiement_status,
    },

    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => showPDFModal(record , "Facture")}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
          <Tooltip title="Envoyer par email">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => openSendEmailModal(record , "Facture")}
              icon={<SendOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const notesCreditCols = [
    {
      title: "Référence Note de credit",
      dataIndex: "reference",
      sorter: (a, b) => a?.reference?.localeCompare(b?.reference),
    },
    {
      title: "Référence Facture",
      dataIndex: "factureRef",
      sorter: (a, b) => a?.factureRef?.localeCompare(b?.factureRef),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a?.showDate?.localeCompare(b?.showDate),
    },
    {
      title: "Client",
      dataIndex: "showClient",
      sorter: (a, b) => a?.showClient?.localeCompare(b?.showClient),
    },

    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(2)} TND</b>
      ),
      sorter: (a, b) => a?.montant_total - b?.montant_total,
    },
    {
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) => a?.devise?.localeCompare(b?.devise),
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (text, record) => (
        <div>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() =>showPDFModal(record , "Note de crédit ")}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
          <Tooltip title="Envoyer par email">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => openSendEmailModal(record , "Note de Crédit")}
              icon={<SendOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const openSendEmailModal = (record , type) => {
    const objet = type + " " + record.reference;
    const destinataire = record.client.email;
    emailForm.setFieldsValue({ objet , destinataire});
    setSelectedFacture(record.id);
    setEmailModal(true);
  };

  



  const searchFatures = async () => {
    setLoading(true);
    const data = yearsForm.getFieldsValue();
    const response = await FactureVenteService.getFactureByEntrepriseAndYear( data.entrepriseId, data.year , data.month);
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };


  const sendEmail = async () => {
    setLoading(true);
    const email = emailForm.getFieldsValue();
    email.factureId = selectedFacture;
    const response = await FactureVenteService.sendMail(email);
    if (response.data) message.success("Email envoyer avec succès");
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
    handleCancelEmailModal();
  };

  const handleCancelEmailModal = () => {
    emailForm.resetFields();
    setEmailModal(false);
    setSelectedFacture(null);
  };

  return (
    <>
      { 
        userType === 1 ? (
          <Spin spinning={isLoading} size="large">
            {/* <Card title={"Factures et Notes de crédit"}> */}
            <Card title={"Factures"}>
              <Form 
                onFinish={searchFatures}
                form={yearsForm} 
                layout="vertical" 
                initialValues={{ year: "2023" , month : 0}}

              >
                <Row>
                  <Col span={12} className="px-2">
                    <Form.Item
                      label="Selectionner la société"
                      name="entrepriseId"
                      extra="Société client"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire !",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase())
                        }
                      >
                        {entreprises &&
                          entreprises.map((entreprise) => (
                            <Select.Option
                              key={entreprise.id}
                              value={entreprise.id}
                            >
                              {entreprise.nom}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5} className="px-2">
                    <Form.Item
                      label="Choisir l'Année"
                      name="year"
                      extra="Année de recherche"

                      rules={[
                        {
                          required: true,
                          message: "champ obligatoire !",
                        },
                      ]}
                    >
                      <Select style={{ width: "100%" }} size="large">
                        {years.map((year) => (
                          <Select.Option key={year} value={year}>
                            {year}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5} className="px-2">
                    <Form.Item
                      label="Mois"
                      name="month"
                      rules={[
                        {
                          required: true,
                          message: "champ obligatoire !",
                        },
                      ]}
                    >
                      <Select style={{ width: "100%" }} size="large">
                        {months.map((month) => (
                          <Select.Option key={month.value} value={month.value}>
                            {month.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                      <Form.Item label=" " >
                      <Button type="primary" size="large" htmlType="submit" >
                          Valider
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Factures" key="1">
                  <TableWithFiltres data={factures} columns={factureCols} />
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab="Notes de crédit" key="2">
                  <TableWithFiltres
                    data={notesCredits}
                    columns={notesCreditCols}
                  />
                </Tabs.TabPane> */}
              </Tabs>
            </Card>
            <Modal
              title={
                <>
                  <div className="d-flex justify-content-between ">
                    <div className="my-0">{selectedRef?.type}</div>
                    <div className="px-5 ">
                      <Button
                        onClick={() => downloadFile()}
                        icon={<DownloadOutlined />}
                      >
                        Telecharger
                      </Button>
                    </div>
                  </div>
                </>
              }
              centered
              visible={showModal}
              footer={null}
              width={1000}
              onCancel={() => {
                setSelectedRef(null);
                setShowModal(false);
              }}
            >
              {showModal && (
                <div className="heigt-500px">
                  <PdfViewerModal file={selectedRef.reference} ></PdfViewerModal>
                </div>
              )}
            </Modal>



            <Modal
              visible={emailModal}
              getContainer={false}
              centered
              onOk={emailForm.submit}
              okText={"Valider"}
              cancelText={"Annuler"}
              onCancel={handleCancelEmailModal}
              title={"Email"}
              width={800}
            >
              <Form layout="vertical" form={emailForm} onFinish={sendEmail}>

                <Row>
                  <Col className="px-2" span={12}>
                    <Form.Item
                      label="Objet"
                      name="objet"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire !",
                        },
                      ]}
                    >
                      <Input size="large" type="text" />
                    </Form.Item>
                  </Col>
                  <Col className="px-2" span={12}>
                    <Form.Item
                      label="E-mail du destinataire"
                      name="destinataire"
                      rules={[
                        {
                          type: "email",
                          message: "Format invalid!",
                        },
                        {
                          required: true,
                          message: "champ obligatoire !",
                        },
                      ]}
                    >
                      <Input size="large" type="text" />
                    </Form.Item>
                  </Col>
                  <Col className="px-2" span={24}>
                    <Form.Item
                      label="Liste des e-mails en copie"
                      name="cc"
                      extra="Les E-mails doivent être séparées par une seule virgule. Exemple : azery@test.com,querty@test.com"
                    >
                      <Input size="large" type="text" />
                    </Form.Item>
                  </Col>
                  <Col className="px-2" span={24}>
                    <Form.Item label="Corps de l'email" name="description" rules={[]}>
                      <TextArea type="text" />
                    </Form.Item>
                  </Col>

                </Row>
                </Form>
            </Modal>
          </Spin>
        ) : (
          <NotAuthorized></NotAuthorized>
        )}
    </>
  );
};
export default DashboardComptbale;
