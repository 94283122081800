import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col, Divider, Form, Input, InputNumber, message, Row,
  Select,
  Spin,
  Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import currencys from '../../../helpers/currency';
import Can from "../../../security/Can";
import ClientService from "../../../services/client.service";
import EntrepriseService from "../../../services/entreprise.service";
import FactureService from "../../../services/factureVente.service";
import TaxeService from "../../../services/taxe.service";
import TvaService from "../../../services/tva.service";
import FicheClient from "../../uiHelpers/Fiches/FicheClient";
import FicheDetails from "../../uiHelpers/Fiches/FicheDetails";
import FicheFooter from "../../uiHelpers/Fiches/FicheFooter";
import FicheFournisseur from "../../uiHelpers/Fiches/FicheFournisseur";
import FicheHeader from "../../uiHelpers/Fiches/FicheHeader";
import FicheTableTotauxFactureVente from "../../uiHelpers/Fiches/FicheTableTotauxFactureVente";
import FicheTableTva from "../../uiHelpers/Fiches/FicheTableTva";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import writtenNumber from '/node_modules/written-number/lib/index';

writtenNumber.defaults.lang = 'fr';
  
  function FactureSimple() {
    const [productsForm] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [fournisseur, setFournisseur] = useState(null);
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState(null);
    const [entreprise, setEntreprise] = useState({});
    const [visualisationModal, setVisualisationModal] = useState(false);
    const [productsModal, setProductsModal] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isDarkMode, setDarkMode] = useState(false);
    const [productList, setProductList] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [appliedTaxes, setListAppliedTaxes] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [gains, setGain] = useState([]);
    const [productModal , setProductModal] = useState(false);
    const [fodec , setFodec] = useState(false);
    const [writtenTotal,setWrittenTotal] = useState("");
    const [selectedCurrency,setSelectedCurrency] = useState("");
    const [tvas , setTvas] = useState([]);


    const history = useHistory();
  
    const location = useLocation();
  
    const [prixTotal, setPrixTotal] = useState({
      montant_total: 0,
      total_ttc: 0,
      total_ht: 0,
      fodec: 0,
      tva: 0,
    });
  
    const isVente = true;
  
    useEffect(() => {
      loadClients();
      loadTaxes();
      loadEntreprise();
      getThemeMode();
      setUpdate();
      return () => {
        clearStates();
      };
    }, []);

    const loadTaxeFodec = async () => {
      const response = await TaxeService.checkTaxeFodec();
      setFodec(response.data);
    }
  
    
  const loadTaxes = async () => {
    setLoading(true);
    const response = await TvaService.getActiveTva();
    if( response.data ) formatteTva(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);

  }
    
  const formatteTva = ( tva ) => {
    setTvas(tva)
  }
    const setUpdate = async () => {
      if( location.state?.id ){
        const id = location.state?.id;
        setLoading(true);
        await FactureService.getFacture( id , ( response )=> {
          if (response.status === 200 || 204) {
            const activeFodec = response.data.fodec > 0 ? true : false; 
            setFodec(activeFodec)
            const productList = { ...response.data }
            productList.produits_achat = response.data.produit_facture_ventes;
            productList.date = moment(productList.date);
            productsForm.setFieldsValue(productList);
            calculeTotalTaxes(activeFodec);
            setSelectedCurrency(response.data.devise);
            const ids = []
            setSelectedIds(ids);
            setUpdateId(id);
            clientChanged();
            setEditMode(true);
          }else message.error("Une erreur est survenue ! ");
          setLoading(false);
        });
      }else{
        await loadTaxeFodec()
        generateReference();
      }
    }

    const generateReference = async () => {
      const year = moment(new Date()).format("YYYY");
      var nbr = "1";
      var reference  = "F" + year + nbr.padStart(4, '0');
      await FactureService.getLast((response) => {
        if (response.data?.reference) {
          const lastRef = response.data.reference;
          const lastFactureYear =  lastRef.substring(1,5);
          if( year === lastFactureYear ){
            nbr = (Number(lastRef.substring(6, 10)) + 1 ) + "";
            reference = "F" + year + nbr.padStart(4, '0');
          }
        }
        productsForm.setFieldsValue({ reference: reference, date: moment(new Date()) });
        addProduct();
        setLoading(false);
      });
    };
  
    
  
  
  
    
    const getThemeMode = () => {
      const theme = localStorage.getItem("COLOR_THEME");
      const darkMode = theme === "DARK" ? true : false;
      setDarkMode(darkMode);
    };
  
    const loadClients = async () => {
      setLoading(true);
      const response = await ClientService.getClients();
      if (response.status === 200 || 204) {
        setClients(response.data);
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
    const getClients = async () => {
      setLoading(true);
      const response = await ClientService.getClients();
      if (response.status === 200 || 204) {
        setLoading(false);
        return response.data;
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
    
  
    const loadEntreprise = async () => {
      setLoading(true);
      const response = await EntrepriseService.getEntreprise();
      if (response.status === 200 || 204) {
        setEntreprise(response.data);
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
  
    const clearStates = () => {
      setEditMode(false);
      setUpdateId(null);
    };
  
    const handleCancel = () => {
      setProductsModal(false);
    };
  
  
    const priceChanged = (index) => {
      const products = productsForm.getFieldValue("produits_achat");
      const product = products[index];
  
      const taxe = product.tva;
      const quantite = product.quantite;
      const prixHT = Number(product.prixHT - product.prixHT * (product.remise / 100)).toFixed(2);
      const prixTTC = (Number(prixHT) + Number((prixHT / 100) * taxe)).toFixed(2);
      const total_ht = Number(prixHT * quantite).toFixed(2);
      const total_ttc = Number(prixTTC * quantite).toFixed(2);
  
      products[index].total_prix_TTC = total_ttc;
      products[index].total_prix_HT = total_ht;
      productsForm.setFieldsValue({ products });
  
      calculeTotalTaxes();
  
    };

    const addProduct = (produit) => {
      const qte = 1;
      const prixUniteHt = 0;
      const prixUniteTTC = 0
      const tva = 16
      const ht =  0;
      const ttc = 0;
      const remise = 0;
      
      const product = {
        libelle: "",
        quantite: Number(qte),
        prixHT: Number(prixUniteHt),
        prixTTC: Number(prixUniteTTC),
        tva: Number(tva),
        remise: Number(remise),
        total_prix_HT: Number(ht).toFixed(2),
        total_prix_TTC: Number(ttc).toFixed(2),
      };
      
      const productList = productsForm.getFieldValue("produits_achat") ? productsForm.getFieldValue("produits_achat") : [];
      productList.push(product);

      productsForm.setFieldsValue({ produits_achat: productList });
      calculeTotalTaxes();
    };
  
  
    const calculeTotalTaxes = async (activeFodec) => {
      const isFodecActive = activeFodec ? activeFodec : fodec;
      const products = productsForm.getFieldValue("produits_achat");
      const list = [];
      for (const product of products) {
        if (product.tva > 0.1) {
          const base = isFodecActive
            ? Number(product.total_prix_HT) +
              Number(product.total_prix_HT * 1) / 100
            : Number(product.total_prix_HT);
          const montantTaxes = isFodecActive
            ? (
                (Number(product.total_prix_HT) +
                  Number(product.total_prix_HT * 0.01)) *
                (product.tva / 100)
              ).toFixed(2)
            : (Number(product.total_prix_HT) * (product.tva / 100)).toFixed(2);
          const taxe = {
            tvaPorucentage: product.tva,
            montantTaxes: montantTaxes,
            base: base,
          };
          list.push(taxe);
        }
      }
      setListAppliedTaxes(list);
      calculateTotal(isFodecActive);
    };
  
  
    const clientChanged = async () => {
      const clientId = productsForm.getFieldValue("clientId");
      const clientList = clients.length ? clients : await getClients();
      const selectedClient =  clientList.find((client) => client.id === clientId);
      setClient( selectedClient );
    };

    const calculateTotal = (activeFodec) => {
      const isFodecActive = activeFodec ? activeFodec : fodec;
      const factureForm = productsForm.getFieldsValue(); 
      const products = factureForm.produits_achat;
      const remiseGlobal = productsForm.getFieldValue("remise_global") ? productsForm.getFieldValue("remise_global") : 0;

      let total_ht = 0;
      let tva = 0;
      let total_ttc = 0;
      for (const product of products) {
        const montantTaxes = isFodecActive ? (Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100) : Number(product.total_prix_HT) * (product.tva / 100);
        total_ht += Number(product.total_prix_HT);
        tva += montantTaxes;
      }

      total_ht = Number(total_ht) - ( Number(total_ht) *  (Number(remiseGlobal) / 100));
      tva =  tva - ( Number(tva) *  (Number(remiseGlobal) / 100));
      let fodecMontant = isFodecActive ? Number(total_ht) / 100 : 0;
      total_ttc += Number(total_ht) + Number(tva) + Number(fodecMontant);
      let montant_total = Number(total_ttc).toFixed(2);
      const total = {
        total_ht,
        tva,
        total_ttc,
        fodec: fodecMontant,
        montant_total,
      };
      setPrixTotal(total);
      const final = montant_total.toString().split(".");
      const currencyName = currencys.find(c => c.code === productsForm.getFieldValue("devise"));
      setWrittenTotal("Le compte est arrêté à la somme de " + writtenNumber(final[0]) + " " +currencyName.base + " , " +final[1] + " " + currencyName.sub);
    };

  
    const addFactureVente = async () => {
      setLoading(true);
      const product = productsForm.getFieldsValue();
      const factureVente = { ...product, ...prixTotal };
      factureVente.etat = 0;
      factureVente.produit_facture_ventes = factureVente.produits_achat;
      factureVente.typeFacture = 0;
      const response = !location.state?.editMode
        ? await FactureService.addFacture(factureVente)
        : await FactureService.updateFacture(updateId, factureVente);
      if (response.status === 200 || 204) {
        location.state?.editMode 
          ? message.info("Facture Mise a jour Avec succès")
          : message.info("Facture Ajouté Avec succès");
        history.push("/facture_vente");
      } else message.error("Une erreur est survenue ! 8");
      setLoading(false);
    }
  
    
    const reloadClients = async () => {
      setLoading(true);
      const response = await ClientService.getClients();
      if (response.status === 200 || 204) {
        setClients(response.data);
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
  
    const changeCurrency = () => {
      const currency = productsForm.getFieldValue("devise");
      setSelectedCurrency(currency);
    }

  
    return (
      <>
        <Spin  className="px-5" spinning={isLoading} size="large" >
          <Card className="w-100 card-shadow">
            <Form layout="vertical" initialValues={{ devise: "EUR", }} form={productsForm} onFinish={addFactureVente}>
              <Row>
                <FicheHeader
                  moduleName={"Facture "}
                  showClients={isVente}
                  entreprise={entreprise}
                  clients={clients}
                  fournisseurs={fournisseurs}
                  selectedClient={() => clientChanged()}
                  reloadClients={() => reloadClients()}

                ></FicheHeader>
              </Row>
              <Row>
                {isVente ? (
                  <Col span={8} className="px-2 pt-3">
                    <FicheClient client={client}></FicheClient>
                  </Col>
                ) : (
                  <Col span={8} className="px-2 pt-3">
                    <FicheFournisseur fournisseur={fournisseur}></FicheFournisseur>
                  </Col>
                )}
                <Col span={8} offset={8} className="px-2 pt-3">
                  <FicheDetails
                    currencyChanged={ ()=>  changeCurrency()}
                  ></FicheDetails>
                </Col>
              </Row>
              <Row>
                  <Col span="24" className="px-4">
                    <div
                      className={` mb-5 table table-like ${
                        isDarkMode ? "table-dark" : ""
                      } `}
                    >
                      <div className="pl-2 thead-like">
                        <Row>
                          <Col span={1}>
                            <b>#</b>
                          </Col>
                          <Col span={8}>
                            <b>Libelle</b>
                          </Col>
                          <Col span={2}>
                              <b>Quantité</b>
                          </Col>

                          <Col span={3}>
                            <Tooltip title="Prix Unitaire HT">
                              <b>Prix HT</b>
                            </Tooltip>
                          </Col>

                          <Col span={3}>
                            <b>TVA</b>
                          </Col>

                          <Col span={2}>
                            <b>Remise</b>
                          </Col>

                          <Col span={2}>
                            <b>Total HT</b>
                          </Col>

                          <Col span={2}>
                            <b>Total TTC</b>
                          </Col>

                          <Col className="px-3" span={1}>
                            <Tooltip title="Ajouter un produit">
                              <Button
                                shape="circle"
                                onClick={() => addProduct()}
                                icon={<PlusCircleOutlined />}
                              />
                            </Tooltip>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Form.List name="produits_achat" label="">
                          {(fields, { add, remove }) => (
                            <>
                              <Row className="pb-1 mb-1 ">
                                {fields.map(
                                  ({ key, name, ...restField }, index) => (
                                    <>
                                      <Col className="mb-0 pb-0" span={1}>
                                        {index + 1}
                                      </Col>
                                      <Col className="mb-0 pb-0" span={8}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "libelle"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:"Champ obligatoire !",
                                            }
                                          ]}
                                        >
                                          <TextArea style={{ width: "100%" }} rows={2} className="mx-0" type="text" />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "quantite"]}
                                        >
                                          <InputNumber
                                            style={{ width: 60 }}
                                            onChange={() => priceChanged(index)}
                                            className="w-125px"
                                            min={0}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={3}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "prixHT"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            onChange={() => priceChanged(index)}
                                            style={{ width: 120 }}
                                            min={0}
                                            formatter={(value) =>
                                              `${value}`
                                            }
                                            parser={(value) =>
                                              value.replace("", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={3}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "tva"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire !",
                                            },
                                          ]}
                                        >
                                          <Select onChange={() => priceChanged(index)} style={{ lineHeight : "30px" ,height: "32px"  }}>
                                            {tvas &&
                                              tvas.map((tva) => (
                                                <Select.Option
                                                  key={tva.id}
                                                  value={tva.valeur}
                                                >
                                                  {tva.valeur} %
                                                </Select.Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "remise"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec un maximum de 100 caractères  !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            onChange={() => priceChanged(index)}
                                            min={0}
                                            max={100}
                                            style={{ width: 60 }}
                                            formatter={(value) => `${value}%`}
                                            parser={(value) =>
                                              value.replace("%", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "total_prix_HT"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec un maximum de 100 caractères  !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={` ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            style={{ width: 120 }}
                                            min={0}
                                            formatter={(value) =>
                                              `${value}`
                                            }
                                            parser={(value) =>
                                              value.replace("", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "total_prix_TTC"]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            style={{ width: 120 }}
                                            min={0}
                                            formatter={(value) =>
                                              `${value}`
                                            }
                                            parser={(value) =>
                                              value.replace("", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0 px-3" span={1}>
                                        <Tooltip title="Supprimer le produit">
                                          <Button
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            onClick={() => { remove(name); calculeTotalTaxes(); }}
                                          />
                                        </Tooltip>
                                      </Col>
                                    </>
                                  )
                                )}
                              </Row>
                            </>
                          )}
                        </Form.List>
                      </div>
                    </div>
                  </Col>
                  <Col span={8} offset={16} className="px-4">
                    <table className={`table ${isDarkMode ? "table-dark" : ""} `}>
                      <thead>
                        <tr>
                          <th scope="col" className="pb-3">
                            Remise :{" "}
                          </th>
                          <th scope="col">
                            <Form.Item
                              name="remise_global"
                              className="my-0 py-1"
                            >
                              <InputNumber
                                onChange={() => calculateTotal()} 
                                style={{ width: "100%" }}
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace("%", "")}
                                min={0}
                                max={100}
                              />
                            </Form.Item>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  <FicheTableTotauxFactureVente
                    fodec={fodec}
                    selectedCurrency={selectedCurrency}
                    isDarkMode={isDarkMode}
                    prixTotal={prixTotal}
                  ></FicheTableTotauxFactureVente>
                  </Col>


                </Row>
              <Divider className="text-capitalize">{writtenTotal}</Divider>

    
              <FicheFooter entreprise={entreprise}></FicheFooter>
    
              <Row className="d-flex flex-row-reverse pt-5 pb-2">
                <Button 
                  className="mx-2" 
                  type="primary"
                  onClick={productsForm.submit}

                >
                  Enregistrer
                </Button>
                
              </Row>
            </Form>
          </Card>
    
          

        </Spin>
      </>
    );
  }
  
  export default FactureSimple;
  