import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Image, Row, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import AddClientModalForm from "../../pages/General/AddClientModalForm";
import { environment } from "../../../env/config.env";
import AddFournisseurModalForm from "../../pages/General/AddFournisseurModalForm";
const FILES_URL = environment.serverUrl;



function FicheHeader({
  moduleName,
  entreprise,
  clients,
  fournisseurs,
  selectedClient,
  selectedFournisseur,
  showClients,
  reloadFournisseur,
  reloadClients
}) {
  const [fournisseurModal, setFournisseurModal] = useState(false);
  const [clientModal, setClientModal] = useState(false);
  const [logo, setLogo] = useState("");

  useEffect(() => {
    setLogo(FILES_URL+"Files/Entreprise/"+entreprise?.image)
  }, [entreprise,fournisseurs,clients]);


  const clientChange = () => {
      selectedClient();
  };

  const fournisseurChange = () => {
    selectedFournisseur();
  };

  const getName = ( e ) => {
    let str = ""
    if( e.nom && e.prenom){
      str = e.nom +" "+ e.prenom + " - "
    }else{
      str = e.matricule_fiscal + " - "
    }
    return str;
  }

  const openAddClientModal = () => {
    setClientModal(true);
  };
  const onClientModalClose = async (action) => {
    if (action) reloadClients()
    setClientModal(false);
  };

  return (
    <>
      <Col span={4} className="text-center px-2">
        <Image src={logo} preview={false} width={"100%"} height={"175px"} />
      </Col>
      <Col span={16}>
        <div className="px-3 mt-3 ligne-height-25">
          <span>
            <strong className="description-text">
              {entreprise?.nom}
            </strong>
          </span>
          <br />
          { entreprise?.societe_tva && 
            <>
            <span>
              <strong> N° TVA : </strong>
            </span>
            <span className="description-text">
              {entreprise?.societe_tva}
            </span>
            <br />
            </>
          }
          { entreprise?.telephone && 
            <>
              <span>
                <strong> Téléphone  :</strong>
              </span>
              <span className="description-text"> {entreprise?.code} {entreprise?.telephone}</span>
              <br />

            </>
          }
          { entreprise?.adresse && 
            <>
              <span>
                <strong> Adresse :</strong>
              </span>
              <span className="description-text"> {entreprise?.adresse} </span>
              <br />
            </>
          }
          { entreprise?.adresse && 
            <>
              <span>
                <strong> E-mail :</strong>
              </span>
              <span className="description-text"> {entreprise?.email} </span> <br />
            </>
          }

          { entreprise?.num_autoriation_etablissement && 
            <>
              <span>
                <strong> Numéro d'autorisation d'établissement :</strong>
              </span>
              <span className="description-text"> {entreprise?.num_autoriation_etablissement} </span> <br />
            </>
          }
          { entreprise?.matricule_societe && 
            <>
              <span>
                <strong> Matricule de la Société :</strong>
              </span>
              <span className="description-text"> {entreprise?.matricule_societe} </span> <br />
            </>
          }

        </div>
      </Col>
      <Col span={4}>
        <div className="w-100 text-right">
          <h3>{moduleName}</h3>
        </div>
      </Col>
      <Col span={8} className="height-50">
        <Col span={24} className="px-2">
            <Row>
              <Col span={21} className="pl-2">
                <Form.Item
                  label="Client :"
                  name="clientId"
                  rules={[
                    {
                      required: true,
                      message: "Champ obligatoire !",
                    },
                  ]}
                >
                  <Select
                    className="w-100 py-0"
                    size="large"
                    placeholder="Client"
                    disabled={moduleName === "Facture"}
                    onChange={() => clientChange()}
                    style={{ width: "100%" }}
                    showSearch
                    optionFilterProp="children"

                  >
                    {clients.map((c) => (
                      <Select.Option key={c.id} value={c.id} className="w-100">
                        { getName(c) } {c.email}  
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={3} className="pr-2">
                <Form.Item label=" ">
                  <Tooltip title="Ajouter un client">
                    <Button
                      className="margin-05"
                      onClick={() => openAddClientModal()}
                      type=""
                      icon={<PlusOutlined />}
                      size={"large"}
                    />
                  </Tooltip>
                  <AddClientModalForm 
                    showModal={clientModal}
                    clientAdded={(action) => onClientModalClose(action)}  
                  ></AddClientModalForm>
                </Form.Item>
              </Col>

            </Row>
        </Col>
      </Col>
      <Col span={8} offset={8} className="px-2">
        <h4 className="pt-5">Détails document</h4>
      </Col>

      <Col span={8}>
        <div className="border"></div>
      </Col>
      <Col span={8} offset={8}>
        <div className="border"></div>
      </Col>
    </>
  );
}
export default FicheHeader;
