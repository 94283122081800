import { Col, Row } from "antd";
import React, { useEffect } from "react";


function FicheFooter({ entreprise }) {
  
  useEffect(() => {}, []);

  return (
    <Row>
      <Col span={16} offset={4} className="fiche-divider-top mt-5 pt-3">
        <div direction="vertical" className="px-2 text-center">  
          {entreprise?.nom && <><span><strong> </strong></span><span className="description-text"> {entreprise?.nom} </span> <br /> </>}
          {entreprise?.adresse && <> <span><strong> Adresse :</strong></span><span className="description-text"> {entreprise?.adresse} </span> </>}
          {entreprise?.telephone && <><span><strong> - Téléphone  : </strong> </span><span className="description-text">{entreprise?.code} {entreprise?.telephone}</span>  </>}
          {entreprise?.email && <><span><strong> - E-mail :</strong> </span><span className="description-text"> {entreprise?.email} </span> </>}
          {entreprise?.site && <><span><strong> - Site :</strong> </span><span className="description-text"> {entreprise?.site}  </span>  </>}
          {entreprise?.societe_tva && <><br /> <span><strong> N° TVA : </strong></span><span className="description-text"> {entreprise?.societe_tva}</span> </>}
          {entreprise?.num_autoriation_etablissement && <><br /> <span><strong> Numéro d'autorisation d'établissement : </strong></span><span className="description-text"> {entreprise?.num_autoriation_etablissement}</span> </>}
          {entreprise?.matricule_societe && <>  -   <span><strong> Matricule de la Société : </strong></span><span className="description-text"> {entreprise?.matricule_societe}</span> </>}
        </div>
      </Col>
    </Row>
    
  );
}
export default FicheFooter;
