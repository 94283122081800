import { Col } from "antd";
import React, { useEffect } from "react";
import { priceFormater } from "../../../helpers/priceFormater";

function FicheTableTotaux({ entreprise,isDarkMode ,prixTotal,selectedCurrency,fodec }) {
  useEffect(() => {
  }, [prixTotal,selectedCurrency]);
  return (
      <table className={`table ${isDarkMode ? "table-dark" : "" } `}>
        <thead>
          <tr>
          <th scope="row">Totaux</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Total HT</th>
            {/* <td>{Number(prixTotal?.total_ht).toFixed(2)} {selectedCurrency}</td> */}
            <td>{priceFormater(prixTotal?.total_ht)} {selectedCurrency}</td>
          </tr>
          { fodec ? (
            <tr>
              <th scope="row">Fodec</th>
              {/* <td>{Number(prixTotal?.fodec).toFixed(2)} {selectedCurrency}</td> */}
              <td>{priceFormater(prixTotal?.fodec)} {selectedCurrency}</td>
            </tr>
          ) : ( <></>)}
          <tr>
            <th scope="row">TVA</th>
            {/* <td>{Number(prixTotal?.tva).toFixed(2)} {selectedCurrency}</td> */}
            <td>{priceFormater(prixTotal?.tva)} {selectedCurrency}</td>
          </tr>
          <tr>
            <th scope="row">Total TTC</th>
            {/* <td>{Number(prixTotal?.total_ttc).toFixed(2)} {selectedCurrency}</td> */}
            <td>{priceFormater(prixTotal?.total_ttc)} {selectedCurrency}</td>
          </tr>
          <tr>
            <th scope="row">Total</th>
            {/* <th scope="row">{Number(prixTotal?.montant_total).toFixed(2)} {selectedCurrency}</th> */}
            <th scope="row">{priceFormater(prixTotal?.montant_total)} {selectedCurrency}</th>
          </tr>
        </tbody>
      </table>
  );
}
export default FicheTableTotaux;
