import { Col, Row, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis,
    YAxis
} from "recharts";
import bonCommandeVente from "../../../services/bonCommandeVente.service";
import FactureAchatService from "../../../services/FactureAchat.service";
import FactureVenteService from "../../../services/factureVente.service";

const colors = [
    "#8884d8","#84D8B2","#D884AA",'#D4D884', '#84AAD8', '#B284D8'
];

const months = [
    "01/2022",
    "02/2022",
    "03/2022",
    "04/2022",
    "05/2022",
    "06/2022",
    "07/2022",
    "08/2022",
    "09/2022",
    "10/2022",
    "11/2022",
    "12/2022",
];

export default function LeadChart() {
    const [isLoading, setLoading] = useState(false);
    const [facturesAchat, setfacturesAchat] = useState([]);
    const [facturesVente, setfacturesVente] = useState([]);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        async function loadData(){
            await getFactures();
        }
        loadData();

    }, []);

    const getFactures = async () => {
        const facturesA = await FactureAchatService.loadFactureMontants(); 
        const facturesV = await FactureVenteService.loadFactureMontants(); 

        const achats = facturesA.data;
        const ventes = facturesV.data;

        const data = [];
        for (const month of months) {
            var achatValue = 0;
            var venteValue = 0;

            for (const element of achats) {
                if(moment(element.date).format("MM/YYYY") + "" === month)
                    achatValue += Number(element.montant_total) 
            }
            for (const element of ventes) {
                if(moment(element.date).format("MM/YYYY") + "" === month)
                    venteValue += Number(element.montant_total)             
            }
            data.push({
                vente : (venteValue).toFixed(2),
                achat : (achatValue).toFixed(2),
                month : month,
            })
        }
        
        setChartData(data);
        setfacturesAchat(facturesA.data);
        setfacturesVente(facturesV.data);

    }
    
    return (
        <Spin spinning={isLoading} size="large">
            <Row>
                <Col span={24}  >
                    <h5 className="p-3">Rapport des ventes  : </h5>
                    <LineChart
                        width={1200}
                        height={400}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="vente" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                </Col>
            </Row>
        </Spin>
    );
}



const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {

      return (
        <div className="custom-tooltip">
            <p className="label"><b>{`${payload[0].payload.payload.name}`} :</b> {`${payload[0].payload.payload.nombre}`}</p>
            <p className="label"><b>Montant :</b>  {`${(payload[0].payload.payload.value).toFixed(2)} TND`}</p>
        </div>
      );
    }
  
    return null;
  };
  