import React from "react";

const PurpleTheme = React.lazy(() => import("./themes/purpleTheme"));
const BlueTheme = React.lazy(() => import("./themes/blueTheme"));
const DarkTheme = React.lazy(() => import("./themes/darkTheme"));
const GreenTheme = React.lazy(() => import("./themes/greenTheme"));
const YellowTheme = React.lazy(() => import("./themes/yellowTheme"));


const ThemeSelector = ({ children }) => {
  const theme = localStorage.getItem("COLOR_THEME") || "PURPLE";
  return (
    <>
      <React.Suspense fallback={<></>}>
        {theme === "GREEN" && <GreenTheme />}
        {theme === "PURPLE" && <PurpleTheme />}
        {theme === "BLUE" && <BlueTheme />}
        {theme === "YELLOW" && <YellowTheme />}
        {theme === "DARK" && <DarkTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

export default ThemeSelector;
