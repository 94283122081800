import {
  EyeOutlined,
  PictureOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin, Tooltip
} from "antd";
import React, { useEffect, useState } from "react";
import { environment } from "../../../env/config.env";
import currency from "../../../helpers/currency";
import entrepriseService from "../../../services/entreprise.service";
import TableWithFiltres from "../../uiHelpers/TableFilters";
import country from "../../../helpers/country";
  
  
  const FILES_URL = environment.serverUrl;
  
  const ClientsComptable = () => {
  
    const [entrepriseForm] = Form.useForm();
  
    
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [fileList, setFileList] = useState([]);
    const [imageUrl, setImageUrl] = useState("");
    const [showImag, setShowImage] = useState(true);
  
    const [entreptriseModal , setEntreptriseModal] = useState(false);
  
    const [dataTable , setDataTable] = useState([])
    const [pictureModal, setPictureModal] = useState(false);
    const [updateEntrepriseName, setUpdateEntrepriseName] = useState("");
  

    useEffect(() => {
      getEntrepriseInfo();
      return () => {
        entrepriseForm.resetFields();
      };
    }, []);
  
    const getEntrepriseInfo = async () => {
      setLoading(true);
      const response = await entrepriseService.getComptableEntreprises();
      if (response.status === 200) initTable(response.data);
      else message.error("Une erreur est survenue ! ");
      setLoading(false);
    };
  
    const initTable = (data) => {
      const list = [];
      for (const element of data) {
          list.push(element.entreprise)
      }
      setDataTable(list);
    }
  
    const entrepriseCols = [
      {
        title: "Nom",
        dataIndex: "nom",
        sorter: (a, b) => a.nom.localeCompare(b.nom),
      },
      {
        title: "TVA",
        dataIndex: "societe_tva",
        sorter: (a, b) => a.societe_tva.localeCompare(b.societe_tva),
      },
      {
        title: "Telephone",
        dataIndex: "telephone",
        sorter: (a, b) => a.telephone.localeCompare(b.telephone),
      },{
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: "Action",
        key: "action",
        width: "15%",
        render: (text, record) => (
          <div>
            <Tooltip title="Visualiser le logo">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => updateLogoMode(record)}
                icon={<PictureOutlined />}
              />
            </Tooltip>
            <Tooltip title="Afficher">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => updateMode(record)}
                icon={<EyeOutlined />}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
  
    const addEntrepriseModal = () => {
      setEntreptriseModal(true);
      setShowImage(false)
  
    };
  
    const handleCancel = () => {
      setEntreptriseModal(false);
      clearData();
    };
  
    const clearData = () => {
      entrepriseForm.resetFields();
      setEditMode(false);
      setImageUrl(null);
      setShowImage(true);
      setUpdateId(null);
    };
  
    const updateMode = (record) => {
      setEntreptriseModal(true);
      entrepriseForm.setFieldsValue(record);

    };
  
    const updateLogoMode = (record) => {
      setPictureModal(true);
      setUpdateEntrepriseName(record.nom)
      const image = FILES_URL + "Files/Entreprise/" + record.image;
      setImageUrl(image);
    }

    const handlePictureModalCancel = () => {
      setUpdateEntrepriseName("");
      setPictureModal(false);
      setShowImage(false);
      setUpdateId(null);
      setEditMode(false);
    }
  
  
  
    return (
      <>
        <Spin spinning={isLoading} size="large">
          <Card title={"Sociétés"} >
            <TableWithFiltres data={dataTable} columns={entrepriseCols} ></TableWithFiltres>
            <Modal
              visible={pictureModal}
              onCancel={handlePictureModalCancel}
              getContainer={false}
              centered
              width={500}
              footer={null}
              title={ updateEntrepriseName }
            >
              <Card title="LOGO">
                <div className="text-center">
                  <Image width={"50%"} src={imageUrl} preview={false} />
                </div>
              </Card>
            </Modal>
            <Modal
              visible={entreptriseModal}
              onCancel={handleCancel}
              getContainer={false}
              centered
              title={"Entreprise "}
              width={900}
              footer={null}

            >
              <Col span={24}>

                  <Form
                    disabled
                    layout="vertical"
                    form={entrepriseForm}
                  >
                    <Row>
                      <Col span={16} className="px-2">
                        <Form.Item
                          label="Nom de l'entreprise"
                          name="nom"
                          rules={[
                            {
                              message:
                                "Champ obligatoire avec un maximum de 100 caractères  !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input disabled size="large" />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Devise"
                          name="devise"
                          rules={[
                            {
                              message: "Champ obligatoire avec un maximum de 100 caractères  !",
                              max: 99,
                            },
                          ]}
                        >
                          <Select  disabled size="large">
                            {currency.map((c) => (
                              <Select.Option key={c.id} value={c.currency}>
                                {c.code}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="px-2">
                        <Form.Item
                          label="TVA"
                          name="societe_tva"
                          rules={[
                            {
                              message:
                                "Champ obligatoire avec un maximum de 100 caractères  !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input disabled size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="px-2">
                        <Form.Item
                          label="Adresse"
                          name="adresse"
                          rules={[
                            {
                              message:
                                "Champ obligatoire avec un maximum de 100 caractères  !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input disabled size="large" />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Adresse Mail"
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "Format invalid!",
                            },
                            {
                              required: true,
                              message: "champ obligatoire !",
                            },
                          ]}
                        >
                          <Input disabled size="large" />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Site web"
                          name="site"
                          rules={[
                            {
                              pattern:
                                /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/g,
                              message: "Format invalid!",
                            },
                            {
                              message:
                                "Champ obligatoire avec un maximum de 100 caractères  !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input disabled size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={5} className="px-2">
                        <Form.Item
                          label="Code Tél"
                          name="code"
                          
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec un maximum de 100 caractères  !",
                            },
                          ]}
                        >
                          <Select size="large" disabled>
                            {country &&
                              country.map((c) => (
                                <Select.Option key={c.id} value={c.telCode}>
                                  {c.telCode} {c.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={7} className="px-2">
                        <Form.Item
                          label="Téléphone"
                          name="telephone"
                          rules={[
                            { required: true,  message: "Champ obligatoire" },
                          ]}
                        >
                          <InputNumber
                            max={99999999999999}
                            size="large"
                            disabled
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Fax"
                          name="fax"
                          rules={[
                            { message: "Champ obligatoire" },
                            {
                              message: "maximum de caractère 10 !",
                              max: 9,
                              min: 6,
                            },
                          ]}
                        >
                          <InputNumber
                            max={99999999999999}
                            size="large"
                            disabled
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
  
                  </Form>
              </Col>
            </Modal>
          </Card>
        </Spin>
      </>
    );
  };
  export default ClientsComptable;
  