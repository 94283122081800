import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addUser = ( user ) => {
  return axios.post(API_URL + "/user/" , user ,{ headers: authHeader() });
};
const getUsers = () => {
  return axios.get(API_URL + "/user/" , { headers: authHeader() });
};
const updateUser = (id , user ) => {
  return axios.put(API_URL + "/user/" + id , user, { headers: authHeader() });
};
const deleteUser = (id) => {
  return axios.delete(API_URL + "/user/" + id , { headers: authHeader() });
};
const getUsersNames = () => {
  return axios.get(API_URL + "/user/names" , { headers: authHeader() });
};
const loadPermissions = () =>{
  return axios.get(API_URL + "/user/permissions" , { headers: authHeader() });
}
const loadModulesPermissions = () => {
  return axios.get(API_URL + "/user/allPermissions" , { headers: authHeader() });
}
const addRole = (role) => {
  return axios.post(API_URL + "/user/role" , role ,{ headers: authHeader() });
}

const getRoles = () => {
  return axios.get(API_URL + "/user/roles" , { headers: authHeader() });  
}
const getRole = (id) => {
  return axios.get(API_URL + "/user/role/" + id , { headers: authHeader() });  
}
const updateRole = (id, data) => {
  return axios.put(API_URL + "/user/role/" + id , data, { headers: authHeader() });  
}
const updatePassword = (id , data) => {
  return axios.put(API_URL + "/user/password/"+id , data , { headers: authHeader() })
}

const updateStatus = (id , etat) => {
  return axios.put(API_URL + "/user/etat/"+id , {etat} , { headers: authHeader() })
}
const updateRoleStatus = (id , etat) => {
  return axios.put(API_URL + "/user/role/etat/"+id , {etat} , { headers: authHeader() })
}



const exportsMethodes = {
  addUser,
  getUsers,
  updateUser,
  deleteUser,
  loadPermissions,
  getUsersNames,
  addRole,
  getRoles,
  loadModulesPermissions,
  getRole,
  updateRole,
  updatePassword,
  updateStatus,
  updateRoleStatus
};
export default exportsMethodes;