import React, { useEffect, useState } from "react";
import { Card, Image, Result } from "antd";
import LeadChart from "./dashboard/LeadChart";
import Can from "../../security/Can";
import { SmileOutlined } from "@ant-design/icons";
import logo from "../../assets/images/black.jpg"


function Home() {
  const [isEntreprise , setIsEntreprise ] = useState(false)

  useEffect(() => {
    checkUserIdentity();
  }, []);

  const checkUserIdentity = async () => {
    const identity = localStorage.getItem("Identity");
    if( identity == 2 ) {
      setIsEntreprise(true);
    }
  }
  return (
    <>
      <Card style={{ width: "100%" }}>
      <h4 className="text-center">
          Bienvenue à APPCOMPTA
          <br />
      </h4>
      </Card>
      { isEntreprise ? 
      <>
        <Card style={{ width: "100%" }}>
        {Can("7_1") ? (
            <div className=" my-5">
              <LeadChart></LeadChart>
            </div>
        ) : (
          <Result
            icon={<SmileOutlined />}
          />  
        )}
        </Card>
      </> : <>
      <Card style={{ width: "100%" }}>
      <div className="text-center">

        <Image
          width={300}
          src={logo}
          preview={false}
        />

      </div>
      </Card>

      </> }
    </>
  );
}
export default Home;


// Your render function
