import React, { useEffect, useState } from "react";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import User from "./User";
  
  const UsersRoles = () => {
    const [userType, setUserType] = useState(true);
    useEffect(() => {
      getUserIdentity();
    }, []);
  
    const getUserIdentity = ()=> {
      setUserType(Number(localStorage.getItem('Identity')))
    }
  
    return (<>
      { userType === 0 ? (
        <User />  
        ) : (
          <NotAuthorized></NotAuthorized>
        )} 
    </>

    );
  };
  export default UsersRoles;
  