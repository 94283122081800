import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addCompteBancaire = ( compteBancaire ) => {
  return axios.post(API_URL + "/compte_bancaire/" , compteBancaire ,{ headers: authHeader() });
};

const getCompteBancaires = () => {
  return axios.get(API_URL + "/compte_bancaire/" , { headers: authHeader() });
};

const updateCompteBancaire = (id , compteBancaire ) => {
  return axios.put(API_URL + "/compte_bancaire/" + id , compteBancaire, { headers: authHeader() });
};

const deleteCompteBancaire = (id) => {
  return axios.delete(API_URL + "/compte_bancaire/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addCompteBancaire,
  getCompteBancaires,
  updateCompteBancaire,
  deleteCompteBancaire,
};
export default exportsMethodes;