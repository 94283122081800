import {
  CloseOutlined,
  EditOutlined, MinusCircleOutlined, PictureOutlined,
  SettingOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  Tooltip,
  Upload
} from "antd";
import "antd-country-phone-input/dist/index.css";
import ImgCrop from "antd-img-crop";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import en from "world_countries_lists/data/countries/en/world.json";
import { environment } from "../../../../env/config.env";
import country from "../../../../helpers/country";
import currency from "../../../../helpers/currency";
import entrepriseService from "../../../../services/entreprise.service";
import userService from "../../../../services/user.service";
import NotAuthorized from "../../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../../uiHelpers/TableFilters";


const API_URL = environment.api;
const FILES_URL = environment.serverUrl;

const Entreprise = () => {
  const [entrepriseForm] = Form.useForm();
  const [searchForm] = Form.useForm();

  
  const [affectationForm] = Form.useForm();
  const [affectationComptabeForm] = Form.useForm();


  const [editMode, setEditMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [showImag, setShowImage] = useState(true);

  const [entreptriseModal, setEntreptriseModal] = useState(false);
  const [affectationModal, setAffectationModal] = useState(false);
  const [affectationModalComptable, setAffectationModalComptable] = useState(false);

  
  const [dataTable, setDataTable] = useState([]);
  const [pictureModal, setPictureModal] = useState(false);
  const [updateEntrepriseName, setUpdateEntrepriseName] = useState("");

  const [comptables, setComptables] = useState([]);
  const [simpleUsers, setSimpleUsers] = useState([]);

  const [personnelId, setPersonnelIds] = useState([]);
  const [userType, setUserType] = useState(true);
  const [finalTabKey, setFinalTabKey] = useState(1);

  var selectUsers = [];

  const onChange = ({ fileList: newFileList, file: uploadedfile }) => {
    setFileList(newFileList);
    if (uploadedfile?.xhr?.response?.length) {
      const res = JSON.parse(uploadedfile?.xhr?.response);
      if (res.status === true) {
        getEntrepriseInfo();
        setFileList([]);
        const image = FILES_URL + "Files/Entreprise/" + res.url;
        setImageUrl(image);
        setShowImage(true);
      } else message.error("Une erreur est survenue ! ");
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  useEffect(() => {
    getUserIdentity();
    getEntrepriseInfo();
    getUsers();

    return () => {
      entrepriseForm.resetFields();
    };
  }, []);

  const getUserIdentity = ()=> {
    setUserType(Number(localStorage.getItem('Identity')))
  }

  const getEntrepriseInfo = async () => {
    setLoading(true);
    const response = await entrepriseService.getEntreprises();
    if (response.status === 200) initTable(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const getUsers = async () => {
    setLoading(true);
    const response = await userService.getUsers();
    if (response.status === 200) initUsersTables(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const getEntreprisePersonnel = async (id) => {
    setLoading(true);
    const response = await entrepriseService.getEntreprisePersonnel(id);
    if (response.status === 200) enablePersonnel(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const enablePersonnel = (data) => {
    const personnels = [];
    for (const element of data) {
      for (const user of selectUsers) {
        if (element.authUserId === user.id) {
          user.exist = false;
          personnels.push(user.id);
        }
      }
    }
    setPersonnelIds(personnels);
  };

  const initUsersTables = async (users) => {
    const artisans = [];
    const comptables = [];
    for (const user of users) {
      if( user.etat === 1 ){
        if (user.type === 1) comptables.push(user);
        else if (user.type === 2) {
          artisans.push(user);
        }
      }
    }
    setSimpleUsers(artisans);
    setComptables(comptables);
  };

  const initTable = (data) => {
    for (const element of data) {
      element.showTel = element.code + " " + element.telephone;
    }
    setDataTable(data);
  };

  const checkIfUserExist = async (id) => {
    setLoading(true);
    const response = await entrepriseService.checkUserEntreprise(id);
    if (response.status === 200) {
      setLoading(false);
      return response.data;
    } else message.error("Une erreur est survenue !  ");
    setLoading(false);
  };

  const addEntreprise = async (entreprise) => {
    setLoading(true);
    entreprise.societe_tva = entreprise.societe_tva ? entreprise.societe_tva : entreprise.nom 
    const response = editMode
      ? await entrepriseService.updateEntreprise(updateId, entreprise)
      : await entrepriseService.addNewEntreprise(entreprise);

    if (response.status === 200) {
      if (editMode) message.info("Mise à jour succès");
      else message.info("Entreprise ajouter avec succès");
    } else message.error("Une erreur est survenue ! ");
    clearData();
    getEntrepriseInfo();
    setEntreptriseModal(false);
    setLoading(false);
  };

  const changeEditMode = () => {
    setEditMode(true);
  };

  const entrepriseCols = [
    {
      title: "Nom",
      dataIndex: "nom",
      sorter: (a, b) => a?.nom.localeCompare(b?.nom),
    },
    {
      title: "TVA",
      dataIndex: "societe_tva",
      sorter: (a, b) => a?.societe_tva.localeCompare(b?.societe_tva),
    },
    {
      title: "Telephone",
      dataIndex: "telephone",
      sorter: (a, b) => a?.telephone.localeCompare(b?.telephone),
    },{
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a?.email.localeCompare(b?.email),
    },
    {
      title: "Action",
      key: "action",
      width: "18%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mise à jours du logo">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => updateLogoMode(record)}
              icon={<PictureOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Affectation du comptable">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => updatePersonnelComptble(record)}
              icon={<UsergroupAddOutlined />}
            />
          </Tooltip>
          <Tooltip title="Affectation de l'artisan">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => updatePersonnelMode(record)}
              icon={<UsergroupAddOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const addEntrepriseModal = () => {
    setEntreptriseModal(true);
    setShowImage(false);
  };

  const handleCancel = () => {
    setEntreptriseModal(false);
    clearData();
    setFinalTabKey(1)
  };

  const clearData = () => {
    entrepriseForm.resetFields();
    setEditMode(false);
    setImageUrl(null);
    setShowImage(true);
    setUpdateId(null);
  };

  const updateMode = (record) => {
    setEntreptriseModal(true);
    setShowImage(true);
    entrepriseForm.setFieldsValue(record);
    setUpdateId(record.id);
    setEditMode(true);
  };

  const updateLogoMode = (record) => {
    setPictureModal(true);
    setUpdateEntrepriseName(record.nom);
    const image = FILES_URL + "Files/Entreprise/" + record.image;
    const show = record.image ? true : false;
    setShowImage(show);
    setImageUrl(image);
    setUpdateId(record.id);
    setEditMode(true);
  };

  const handlePictureModalCancel = () => {
    setUpdateEntrepriseName("");
    setPictureModal(false);
    setShowImage(false);
    setUpdateId(null);
    setEditMode(false);
  };

  const updatePersonnelMode = (record) => {
    const data = record;
    data.usersId = [];
    for (const element of data.auth_user_entreprises) {
      if (element.type === 1) data.comptableId = element.authUserId;
      else data.usersId.push(element.authUserId);
    }
    affectationForm.setFieldsValue(data);
    getEntreprisePersonnel(record.id);
    setUpdateEntrepriseName(record.nom);
    setUpdateId(record.id);
    setAffectationModal(true);
  };


  const updatePersonnelComptble = (record) => {
    const data = record;
    data.usersId = [];
    for (const element of data.auth_user_entreprises) {
      if (element.type === 1) data.comptableId = element.authUserId;
      else data.usersId.push(element.authUserId);
    }
    affectationComptabeForm.setFieldsValue(data);
    getEntreprisePersonnel(record.id);
    setUpdateEntrepriseName(record.nom);
    setUpdateId(record.id);
    setAffectationModalComptable(true);
  }

  const handleAffectationModalCancel = () => {
    setAffectationModal(false);
    setUpdateEntrepriseName("");
    affectationForm.resetFields();
    setUpdateId(null);
  };

  const handleAffectationModalComptabeCancel = () => {
    setAffectationModalComptable(false);
    affectationComptabeForm.resetFields();
    setUpdateEntrepriseName("");
    setUpdateId(null);
  };

  const addAffecation = async (values) => {
    setLoading(true);
    const data = [];

    for (const element of values.usersId)
      data.push({ entrepriseId: updateId, authUserId: element, type: 2 });
    
      const response = await entrepriseService.addAffectation(updateId, data);
    if (response.status === 200) message.info("Mise à jour avec succès");
    else message.error("Une erreur est survenue ! ");
    getEntrepriseInfo();
    clearAffectationData();

    getUsers();
    setLoading(false);
  };

  const addComptableAffecation = async (values) => {
    setLoading(true);
    const data = [];

    data.push({
      entrepriseId: updateId,
      authUserId: values.comptableId,
      type: 1,
    });

    const response = await entrepriseService.addAffectation(updateId, data);
    if (response.status === 200) message.info("Mise à jour avec succès");
    else message.error("Une erreur est survenue ! ");
    getEntrepriseInfo();
    clearAffectationData();
    clearAffectationData();

    getUsers();
    setLoading(false);
  };

  

  const clearAffectationData = () => {
    setEditMode(false);
    setUpdateId(null);
    setAffectationModal(false);
    setAffectationModalComptable(false);
    affectationForm.resetFields();
    affectationComptabeForm.resetFields();

  };


  const searchData = () => {
    const data = searchForm.getFieldsValue();
    const result = [];
    for (const element of dataTable) {
      let exist = false;
      for (const key in element) {
        if( typeof(element[key]) === "string" || typeof(element[key]) === "number" ){
          const k = "" + element[key]; 
          if(k.toLowerCase().includes(data.search.toLowerCase())) exist = true

        }
      }     
      if( exist ) result.push(data);
    }
    setDataTable(result);
  }

  const handleTabsChange = (key) => {
    isNaN(key) ? setFinalTabKey(2) : setFinalTabKey(key) 
  }


  return (
    <>
    { userType === 0  || userType === 1 ? (
      
      <Spin spinning={isLoading} size="large">
        <Card
          title={"Sociétés"}
          extra={
            <Button
              className="mx-2"
              shape="round"
              type="primary"
              onClick={() => addEntrepriseModal()}
            >
              Ajouter une Société
            </Button>
          }
        >
          <TableWithFiltres
            data={dataTable}
            columns={entrepriseCols}
          ></TableWithFiltres>
          <Modal
            visible={pictureModal}
            onCancel={handlePictureModalCancel}
            getContainer={false}
            centered
            width={500}
            footer={null}
            title={"Mise à jours du logo de : " + updateEntrepriseName}
          >
            <Card
              title="LOGO"
              extra={
                showImag ? (
                  <Tooltip title="Changer le logo">
                    <Button
                      onClick={() => {
                        setShowImage(false);
                      }}
                      type="primary"
                      shape="circle"
                      icon={<SettingOutlined />}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Annuler ">
                    <Button
                      onClick={() => {
                        setShowImage(true);
                      }}
                      type="primary"
                      shape="circle"
                      icon={<CloseOutlined />}
                    />
                  </Tooltip>
                )
              }
            >
              <div className="text-center">
                {showImag ? (
                  <Image width={"50%"} src={imageUrl} preview={false} />
                ) : (
                  <ImgCrop rotate>
                    <Upload
                      multiple={false}
                      action={API_URL + "/entreprise/logo/" + updateId}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreview}
                    >
                      Choisir
                    </Upload>
                  </ImgCrop>
                )}
              </div>
            </Card>
          </Modal>
          <ConfigProvider locale={en}>
            <Modal
              visible={entreptriseModal}
              onOk={finalTabKey == 2 ? entrepriseForm.submit : handleTabsChange}
              onCancel={handleCancel}
              getContainer={false}
              okText={finalTabKey == 2 ? "Enregistrer" : "Suivant"}
              cancelText="Annuler"
              centered
              width={900}
              title={editMode ? "Mise à jours d'une entreprise" : "Ajouter une entreprise"}
            >
              <Col span={24}>
                <Form
                  layout="vertical"
                  form={entrepriseForm}
                  onFinish={addEntreprise}
                  initialValues={{ devise: "EUR" , code : "+352"}}
                >
                  <Tabs activeKey={`${finalTabKey}`} onChange={handleTabsChange}>
                    <Tabs.TabPane tab="Informations Genérale" key="1">
                      <Row>
                        <Col span={16} className="px-2">
                          <Form.Item
                            label="Nom Société"
                            name="nom"
                            rules={[
                              {
                                message:
                                  "Champ obligatoire avec un maximum de 100 caractères  !",
                                max: 99,
                              },
                              {
                                required: true,
                                message: "Champ obligatoire",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col span={8} className="px-2">
                          <Form.Item
                            label="Devise"
                            name="devise"
                            rules={[
                              {
                                message:
                                  "Champ obligatoire avec un maximum de 100 caractères  !",
                                max: 99,
                              },
                            ]}
                          >
                            <Select size="large">
                              {currency.map((c) => (
                                <Select.Option key={c.id} value={c.currency}>
                                  {c.code}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                      <Col span={12} className="px-2">
                          <Form.Item
                            label="Numéro autorisation et Etablisement"
                            name="num_autoriation_etablissement"
                            rules={[
                              {
                                message:
                                  "Champ obligatoire avec un maximum de 100 caractères  !",
                                max: 99,
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Matricule Société"
                            name="matricule_societe"
                            rules={[
                              {
                                message:
                                  "Champ obligatoire avec un maximum de 100 caractères  !",
                                max: 99,
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24} className="px-2">
                          <Form.Item
                            label="TVA"
                            name="societe_tva"
                            rules={[
                              {
                                message:
                                  "Champ obligatoire avec un maximum de 100 caractères  !",
                                max: 99,
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={18} className="px-2">
                          <Form.Item
                            label="Adresse"
                            name="adresse"
                            rules={[
                              {
                                message:
                                  "Champ obligatoire avec un maximum de 100 caractères  !",
                                max: 99,
                              },
                              {
                                required: true,
                                message: "Champ obligatoire",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col span={6} className="px-2">
                          <Form.Item
                            label="Numéro"
                            name="num_adresse"
                            rules={[
                              {
                                message:
                                  "Champ obligatoire avec un maximum de 100 caractères  !",
                                max: 99,
                              },
                              {
                                required: true,
                                message: "Champ obligatoire",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Adresse Mail"
                            name="email"
                            rules={[
                              {
                                type: "email",
                                message: "Format invalid!",
                              },
                              {
                                required: true,
                                message: "champ obligatoire !",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Site web"
                            name="site"
                            rules={[
                              {
                                pattern:/^((http|https):\/\/.)|(www.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g,
                                message: "Format invalid!",
                              },
                              {
                                message:
                                  "Champ obligatoire avec un maximum de 100 caractères  !",
                                max: 99,
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={5} className="px-2">
                          <Form.Item
                            label="Code Tél"
                            name="code"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Champ obligatoire avec un maximum de 100 caractères  !",
                              },
                            ]}
                          >
                            <Select size="large">
                              {country &&
                                country.map((c) => (
                                  <Select.Option key={c.id} value={c.telCode}>
                                    {c.telCode} {c.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={7} className="px-2">
                          <Form.Item
                            label="Numéro Téléphone "
                            name="telephone"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Champ obligatoire avec un maximum de 100 caractères  !",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              style={{ width: "100%" }}
                              min={0}
                              max={99999999999999}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Fax"
                            name="fax"
                          >
                            <Input
                              max={99999999999999}
                              size="large"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Comptes Bancaires" key="2"  forceRender={true}>
                      <Row>
                        <Form.List name="compte_bancaires">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <>
                                  <Col span={24} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "nom"]}
                                      label="Intitulé du compte"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Champ obligatoire avec un maximum de 100 caractères  !",
                                        },
                                      ]}
                                    >
                                      <Input size="large" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={15} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "iban"]}
                                      label="IBAN"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Champ obligatoire avec un maximum de 100 caractères  !",
                                        },
                                      ]}
                                    >
                                      <Input size="large" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "bic"]}
                                      label="BIC"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Champ obligatoire avec un maximum de 100 caractères  !",
                                          max: 99,
                                        },
                                      ]}
                                    >
                                      <Input size="large" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={1} className="px-2 mt-2">
                                    <Form.Item label=" ">
                                      <MinusCircleOutlined
                                        size="large"
                                        onClick={() => remove(name)}
                                      />
                                    </Form.Item>
                                  </Col>
                                </>
                              ))}
                              <Row>
                                <Col span={12} className="px-2 mt-3">
                                  <Button type="dashed" onClick={() => add()}>
                                    Autre banque
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Form.List>
                      </Row>
                    </Tabs.TabPane>
                  </Tabs>
                </Form>
              </Col>
            </Modal>
          </ConfigProvider>

          <Modal
            visible={affectationModal}
            onCancel={handleAffectationModalCancel}
            getContainer={false}
            centered
            width={800}
            onOk={affectationForm.submit}
            okText="Enregistrer"
            cancelText="Annuler"
            title={
              "Affectation de l'artisan : " +
              updateEntrepriseName
            }
          >
            <Form
              disabled
              layout="vertical"
              form={affectationForm}
              onFinish={addAffecation}
              
            >
              <Col span={24} className="px-2">
                <Form.Item label="Liste des artisans" name="usersId"
                  rules={[
                    {
                      required: true,
                      message: "Champ obligatoire !",
                    },
                  ]}>
                  <Select size="large" 
                    mode="multiple"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {simpleUsers.map((u) => (
                      <Select.Option disabled={u.exist} key={u.id} value={u.id}>
                        {u.nom_prenom}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Form>
          </Modal>
          <Modal
            visible={affectationModalComptable}
            onCancel={handleAffectationModalComptabeCancel}
            getContainer={false}
            centered
            width={800}
            onOk={affectationComptabeForm.submit}
            okText="Enregistrer"
            cancelText="Annuler"
            title={
              "Affectation de l'expert comptable : " +
              updateEntrepriseName
            }
          >
            <Form
              disabled
              layout="vertical"
              form={affectationComptabeForm}
              onFinish={addComptableAffecation}
            >
              <Col span={24} className="px-2">
                <Form.Item
                  label="Liste des Comptables"
                  name="comptableId"
                  rules={[
                    {
                      required: true,
                      message: "Champ obligatoire !",
                    },
                  ]}
                >
                  <Select size="large"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {comptables.map((comptable) => (
                      <Select.Option key={comptable.id} value={comptable.id}>
                        {comptable.nom_prenom}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

            </Form>
          </Modal>
        </Card>
      </Spin>
      ) : (
      <NotAuthorized></NotAuthorized>
    )}
    </>

  );
};
export default Entreprise;
