import React from 'react';

import { Layout } from 'antd';
const { Footer } = Layout;

function AppFooter() {
  return (
    <Footer style={{ textAlign: 'center' }}>APPCOMPTA ©2023</Footer>
  );
}
export default AppFooter;
