import React from "react";
import Tva from "./Tva";

const TaxeAndTva = () => {
  

  return (
    <>
        <Tva />
    </>
  );
};
export default TaxeAndTva;
