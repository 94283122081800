import {
  AuditOutlined,
  BankOutlined,
  DiffOutlined, DollarOutlined,
  GlobalOutlined, IdcardOutlined,
  ShoppingCartOutlined,
  TeamOutlined
} from "@ant-design/icons";
import { Image, Menu } from "antd";
import { React, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import backPhoto from "../../assets/images/black.jpg";
const { SubMenu } = Menu;

function SideNavigation() {
  const history = useHistory();
  const location = useLocation();
  const [myKey, setMyKey] = useState([]);
  const [openSub, setOpenSub] =  useState([]);
  const [userType , setUserType]= useState(false);

  const navigate = (location) => {
    history.push(location);
  };
  useEffect(() => {
    getRoute();
    getIdentity();
  }, [location]);


  const getIdentity = () => {
    const identity = localStorage.getItem("Identity");
    const userType = Number(identity); 
    setUserType(userType);

  }

  const getRoute = () => {
    const selectedRoute = [];
    window.scrollTo({ top: 0, behavior: "smooth" });
    switch (location.pathname) {
      case "/":
        selectedRoute.push("1");
        break;
      case "/socite":
        selectedRoute.push("2");
        break;
      case "/tva":
        selectedRoute.push("3");
        break;
      case "/users":
        selectedRoute.push("4");
        break;
      case "/v/comptable_client":
        selectedRoute.push("5");
        break;
      case "/v/client_factures":
        selectedRoute.push("6");
        break;
      case "/client":
        selectedRoute.push("7");
        break;
      case "/devis_list":
        selectedRoute.push("8");
        break;
      case "/facture_vente":
        selectedRoute.push("9");
        break;
      // case "/v/note_credit":
      //   selectedRoute.push("10");
      //   break;
      default:
    }
    setMyKey(selectedRoute);
  };

  return (
    <>
      <div className="my-2 py-2 logo ">
        <div className="text-center">
          <Image
            preview={false}
            src={backPhoto}
            className="backGroundPic px-5 "
            // style={{backgroundColor:"white"}}
            // width={200}
            // height={120}
          />
        </div>
      </div>

      <Menu theme="dark" selectedKeys={myKey} mode="inline">
        <Menu.Item
          key="1"
          icon={<BankOutlined />}
          onClick={() => navigate("/")}
        >
          Acceuil
        </Menu.Item>
        {userType === 0 ?
          <>

            <Menu.Item
              key="2"
              icon={<GlobalOutlined />}
              onClick={() => navigate("/societe")}
            >
              Sociétés
            </Menu.Item>
            <Menu.Item
              key="3"
              icon={<DollarOutlined />}
              onClick={() => navigate("/tva")}
            >
              Taxe et TVA 
            </Menu.Item>
            <Menu.Item
              key="4"
              icon={<TeamOutlined />}
              onClick={() => navigate("/users")}
            >
              Utilisateurs
            </Menu.Item>
          </> : <></>
        }
        {userType === 1 ?
          <>

            <Menu.Item
              key="5"
              icon={<IdcardOutlined />}
              onClick={() => navigate("/v/comptable_client")}
            >
              Clients
            </Menu.Item> 
            <Menu.Item    
              key="6"
              icon={<AuditOutlined />}
              onClick={() => navigate("/v/client_factures")}
            >
              {/* Factures et NC */}
              Factures
            </Menu.Item> 

          </>
        : <> </>}
        {userType === 2 ?
        <>
          <Menu.Item
            key="7"
            icon={<IdcardOutlined />}
            onClick={() => navigate("/client")}
          >
            Clients
          </Menu.Item>
          <Menu.Item
            icon={<DiffOutlined />}
            key="8"
            onClick={() => navigate("/devis_list")}
          >
            Devis
          </Menu.Item>
          <Menu.Item
            icon={<AuditOutlined />}
            key="9"
            onClick={() => navigate("/facture_vente")}
          >
            Facture
          </Menu.Item>
          {/* <Menu.Item
            icon={<ShoppingCartOutlined />}
            key="10"
            onClick={() => navigate("/v/note_credit")}
          >
            Notes de crédits
          </Menu.Item> */}
        
        </> : <></>}
          

      </Menu>
    </>
  );
}
export default SideNavigation;
