import authHeader from "./auth-header";
import axios from "axios";
import { environment } from "../env/config.env";
const API_URL = environment.api;

const checkToken = ( token ) => {
  return axios.post(API_URL + "/user/verif_validity/" , token ,{ headers: authHeader() });
};
const exportsMethodes = {
  checkToken,
};
export default exportsMethodes;