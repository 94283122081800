import {
  CheckOutlined, DownloadOutlined, EditOutlined, FilePptOutlined, SendOutlined
} from "@ant-design/icons";
import {
  Button,
  Card, Col, Dropdown, Form, Input, Menu,
  message,
  Modal, Row, Select,
  Spin, Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import { priceFormater } from "../../../helpers/priceFormater";
import PdfViewerModal from "../../../PdfViewerModal";
import DevisService from "../../../services/devis.service";
import entrepriseService from "../../../services/entreprise.service";
import FactureVenteService from "../../../services/factureVente.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";
const url = environment.serverUrl;

const DashboardFactureVente = () => {
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [devisModal, setBonCommandeModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [factureInfo,setFactureInfo] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [reference,setReference] = useState();
  const [devis, setDevis] = useState([]);
  const [isEntreprise , setIsEntreprise] = useState(false);
  const [entreprises , setEntreprises ] = useState([]);
  const [selectedFacture, setSelectedFacture] = useState();
  const [emailModal, setEmailModal] = useState(false);
  const [emailForm] = Form.useForm();

  const [userType, setUserType] = useState(true);

  const [currentEntreprise , setCurrentEntreprise] = useState();

  const DEFAULT_EMAIL_DESCRIPTION = "Cher client, Veuillez trouvait ci-joint la facture"

  useEffect(() => {
    getEntrepriseInfo();
    checkUserIdentity();
    getUserIdentity();

    return () => {
      clearStates();
    };
  }, []);

  const getUserIdentity = ()=> {
    setUserType(Number(localStorage.getItem('Identity')))
  }

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };

  const getEntrepriseInfo = async () => {
    setLoading(true);
    const response = await entrepriseService.getEntreprise();
    if (response.data) {
      setEntreprises(response.data);
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };
  

  const checkUserIdentity = async () => {
    const identity = localStorage.getItem("Identity");
    if( identity == 2 ) {
      setIsEntreprise(true);
      loadEntrepriseFactures();
    }
  }


  const loadEntrepriseFactures = async () => {
    setLoading(true);
    const response = await FactureVenteService.getEntrepriseFactures();
    if (response.status === 200 || 204) initTableData(response.data)
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  }


  const initTableData = async (data, clients) => {
    const list = [];
    for (const element of data) {
        element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
        element.showClient = element.client.raison_social ? element.client.raison_social :  element.client.nom + " " + element.client.prenom;
        element.devisRef = await getCommandeReference(element.bonCommandeId);
        element.status = getFactureStatus(element.paiement_status);
        element.paiement_status = element.paiement_status ? element.paiement_status : 0
        element.montant_total = priceFormater(element.montant_total)
        list.push(element);
    }
    setDataTable(list);
  };

  const getFactureStatus = (status) =>{
    switch (status) {
      case  1:
        return "Payé";
      case  2:
        return "Payé Partiellement";
      default:
        return "Non Payé";
    }
  };

  const getCommandeReference = async (bonCommandeId) => {
    const response = await DevisService.getReference(bonCommandeId);
    return response.data.reference;
  };

  const formatteEtat = (etat ,pStatus) => {
    switch (etat) {
      case 0:
        // return "En cours";
        return "Non Payé"
      case 1:
        // if( pStatus) return "Facturé"
        // else return "Validé";
        // else return "Payé"
        return "Payé"
      case -1:
        return "Annulé";
      default:
        return "";
    }
  };

  const formatteEtatPayement = (etat) => {
    switch (etat) {
      case 0:
        return "Non Payé";
      case 1:
        return "Payé";
      default:
        return "";
    }
  };

  const changeBonStatus = async (id, etat) => {
    const response = await FactureVenteService.changeEtat(id, etat);
    if (response.status === 200 || 204) {
      const msg = etat === 1 ? "activé" : "desactivé";
      message.info("Facture " + msg);
    } else message.error("Une erreur est survenue ! ");

    if( currentEntreprise ) entrepriseChanged(currentEntreprise);
    else loadEntrepriseFactures();
  };

  const changePaiementStatus = async (id, etat, record) => {
    setLoading(true);
    const response = await FactureVenteService.changePaymentStatus(id, etat);
    if (response.status === 200 || 204) {
      const msg = etat === 1 ? "Payé" : "Non Payé";
      message.info("Facture " + msg);
    } else message.error("Une erreur est survenue ! ");

    if( currentEntreprise ) entrepriseChanged(currentEntreprise);
    else loadEntrepriseFactures();
    setLoading(true);
  }

  const goToBonReception = (record) => {
    const id = record.id;
    const editMode = false;
    history.push("/factureVente", { id, editMode });
  };

  const navigateToUpdate = (record) => {
    const id = record.id;
    const editMode = true;
    if( record.bonCommandeId !== null){
      history.push("/factureVente", { id, editMode });
    }else {
      history.push("/s/facture", { id, editMode });
    }
  };


  const addFactureSimple = () => {
    const editMode = false;
    history.push("/s/facture", { editMode });
  }

  const openDevisModal = async () => {
    setLoading(true);
    await DevisService.getActiveDevis((response) => {
      if (response.status === 200 || 204) {
        const data = response.data;
        for (const element of data) {
          element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
          // element.showClient = element.client.raison_social ? element.client.raison_social :  element.client.nom + " " + element.client.prenom;
          element.showClient = element.client.type == 0 ? element.client.nom + " " + element.client.prenom : element.client.raison_sociale ;
          element.montant_total = priceFormater(element?.montant_total)
        }
        setDevis(response.data);
        setBonCommandeModal(true);
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    });
  };



  const showPDFModal = (record) => {
    setShowModal(true);
    const ref = "Files/Vente/Facture/E-"+record.entrepriseId + "/" + record.reference + ".pdf"
    setReference(ref)
  } 

  const downloadFile = () => {
    window.open(url + reference, 'download');
  }



  const factureCols = [
    {
      title: "Numéro Facture",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Numéro Devis",
      dataIndex: "devisRef",
      sorter: (a, b) => a?.devisRef?.localeCompare(b?.devisRef),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Client",
      dataIndex: "showClient",
      sorter: (a, b) => a.showClient.localeCompare(b.showClient),
    },

    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(2)} {factureInfo.devise}</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },{
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) =>a.devise.localeCompare(b.devise),
    },
    // {
    //   title: "Etat de Paiement",
    //   dataIndex: "paiement_status",
    //   render: (text, record) => (
    //     <Dropdown.Button
    //       overlay={
    //         <Menu>
    //           <Menu.Item
    //             className="px-5"
    //             key="1"
    //             disabled={record.paiement_status === 1}
    //             onClick={() => changePaiementStatus(record.id, 1,record)}
    //           >
    //             Payé
    //           </Menu.Item>
    //           <Menu.Item
    //             className="px-5"
    //             key="2"
    //             disabled={record.paiement_status === 0}
    //             onClick={() => changePaiementStatus(record.id, 0,record)}
    //           >
    //             Non Payé
    //           </Menu.Item>
    //         </Menu>
    //       }
    //     >
    //       {formatteEtatPayement(record.paiement_status)}
    //     </Dropdown.Button>
    //   ),
    // },
    {
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => (
        <Dropdown.Button
          overlay={
            <Menu disabled={record.paiement_status}>
              <Menu.Item
                className="px-5"
                key="1"
                disabled={record.etat === 1}
                onClick={() => changeBonStatus(record.id, 1)}
              >
                {/* Validé */}
                Payé
              </Menu.Item>
              <Menu.Item
                className="px-5"
                key="2"
                disabled={record.etat === -1}
                onClick={() => changeBonStatus(record.id, -1)}
              >
                Annulé
              </Menu.Item>
            </Menu>
          }
        >
          {record.etat == 0 ? <span className="text-danger">{formatteEtat(record.etat ,record.paiement_status)}</span>
                            : record.etat == 1 ? <span className="text-success">{formatteEtat(record.etat ,record.paiement_status)}</span>
                            : <span className="text-dark">{formatteEtat(record.etat ,record.paiement_status)}</span>
          }
        </Dropdown.Button>
      ),
    },
    {
      title: "Action",
      key: "action",
      width :"13%",
      render: (text, record) => (
        <div>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => showPDFModal(record)}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
          <Tooltip title="Envoyer par email">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => openSendEmailModal(record , "Facture")}
              icon={<SendOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={record.etat === 1 || isEntreprise}
              onClick={() => navigateToUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          
        </div>
      ),
    },
  ];

  const devisCols = [
    {
      title: "Référence Devis",
      dataIndex: "reference",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "Client",
      dataIndex: "showClient",
      sorter: (a, b) => a.showClient.localeCompare(b.showClient),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(2)} TND</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Devise",
      dataIndex: "devise",
      sorter: (a, b) => a.devise.localeCompare(b.devise),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Choisir">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => goToBonReception(record)}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleCancel = () => {
    setBonCommandeModal(false);
  };
  
  const entrepriseChanged = async (e) =>{
    setLoading(true);
    setCurrentEntreprise(e);
    const response = await FactureVenteService.getFactureByEntreprise(e);
    if (response.status === 200 || 204) initTableData(response.data)
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  }

  
  const sendEmail = async () => {
    setLoading(true);
    const email = emailForm.getFieldsValue();
    email.factureId = selectedFacture;
    const response = await FactureVenteService.sendMail(email);
    if (response.data) message.success("Email envoyer avec succès");
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
    handleCancelEmailModal();
  };
  const openSendEmailModal = (record , type) => {
    const objet = type + " " + record.reference;
    const destinataire = record.client.email;
    emailForm.setFieldsValue({ objet,destinataire });
    setSelectedFacture(record.id);
    setEmailModal(true);
  };

  const handleCancelEmailModal = () => {
    emailForm.resetFields();
    setSelectedFacture(null);
    setEmailModal(false);
  };
  

  return (
    <>
      { userType === 2 ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Factures"}
            extra={
              isEntreprise ?
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={() => openDevisModal()}
              >
                Ajouter Une Facture
              </Button>
              :
              <Select placeholder="Entreprise" onChange={(e) => entrepriseChanged(e)} style={{width: "250px"}} >
                {entreprises &&
                  entreprises.map((entreprise) => (
                    <Select.Option
                      key={entreprise.id}
                      value={entreprise.id}
                    >
                      {entreprise.raison_social} 
                    </Select.Option>
                  ))}
              </Select>
            }
          >
            <TableWithFiltres data={dataTable} columns={factureCols} />
          </Card>
          <Modal
            title={
              <>
                <div className="d-flex justify-content-between ">
                  <div className="my-0">
                    Facture
                  </div>
                  <div className="px-5 ">
                    <Button  onClick={()=> downloadFile()} icon={<DownloadOutlined />} >
                      Telecharger
                    </Button>
                  </div>
                </div>
              </>
            }
            centered
            visible={showModal}
            footer={null}
            width={1000}
            onCancel={()=> setShowModal(false)}
          >
          { showModal && 
            <div className="heigt-500px">
              <PdfViewerModal file={reference} ></PdfViewerModal>
            </div>
          }
          

        </Modal>
        

        <Modal
          visible={emailModal}
          getContainer={false}
          centered
          onOk={emailForm.submit}
          okText={"Valider"}
          cancelText={"Annuler"}
          onCancel={handleCancelEmailModal}
          title={"Email"}
          width={800}
        >
          <Form layout="vertical" form={emailForm} onFinish={sendEmail} initialValues={{ ["description"]: DEFAULT_EMAIL_DESCRIPTION }} >

              <Row>
                <Col className="px-2" span={12}>
                  <Form.Item
                    label="Objet"
                    name="objet"
                    rules={[
                      {
                        required: true,
                        message: "Champ obligatoire !",
                      },
                    ]}
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
                <Col className="px-2" span={12}>
                  <Form.Item
                    label="E-mail du destinataire"
                    name="destinataire"
                    rules={[
                      {
                        type: "email",
                        message: "Format invalid!",
                      },
                      {
                        required: true,
                        message: "champ obligatoire !",
                      },
                    ]}
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
                <Col className="px-2" span={24}>
                  <Form.Item
                    label="Liste des e-mails en copie"
                    name="cc"
                    extra="Les E-mails doivent être séparées par une seule virgule. Exemple : azery@test.com,querty@test.com"
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
                <Col className="px-2" span={24}>
                  <Form.Item label="Corps de l'email" name="description" rules={[]}>
                    <TextArea type="text" />
                  </Form.Item>
                </Col>

              </Row>
          </Form>
        </Modal>
        <Modal
          visible={devisModal}
          getContainer={false}
          centered
          footer={<Button className="mx-2" onClick={() => addFactureSimple()}>Ajouter Une Facture Simple </Button>}
          onCancel={handleCancel}
          title={"Liste des devis"}
          width={800}
        >
          <Spin spinning={isLoading} size="large">
            <TableWithFiltres data={devis} columns={devisCols} />
          </Spin>
        </Modal>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default DashboardFactureVente;
