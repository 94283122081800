import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addBonCommande = async ( bon_commande ) => {
  return await axios.post(API_URL + "/v/bon_commande" , bon_commande ,{ headers: authHeader() });
};

const getBonCommandes = async ( callback ) => {
  callback( await axios.get(API_URL + "/v/bon_commande" , { headers: authHeader() }))
};

const getBonCommandesWithoutActiveFacture = async (callback) => {
  callback( await axios.get(API_URL + "/v/bon_commande/acompte" , { headers: authHeader() }))

}

const getReference = (id , bon_commande ) => {
  return axios.get(API_URL + "/v/bon_commande/ref/" + id , { headers: authHeader() });
};

const getBonCommande = async ( id, callback ) => {
  callback( await axios.get(API_URL + "/v/bon_commande/" + id , { headers: authHeader() }))
};
const updateBonCommande = (id , bon_commande ) => {
  return axios.put(API_URL + "/v/bon_commande/" + id , bon_commande, { headers: authHeader() });
};

const deleteBonCommande = (id) => {
  return axios.delete(API_URL + "/v/bon_commande/" + id , { headers: authHeader() });
};
const changeEtat = (id ,etat) => {
  return axios.post(API_URL + "/v/bon_commande/state/" + id , {etat : etat} , { headers: authHeader() });
};

const getActiveReception = async (callback) => {
  callback (await axios.get(API_URL + "/v/bon_commande/active/livraisons" ,{ headers: authHeader() }));
}
const getActiveFacturation = async (callback) => {
  callback (await axios.get(API_URL + "/v/bon_commande/active/facture" ,{ headers: authHeader() }));
}

const getActiveByDevis = async (id) => {
  return await axios.get(API_URL + "/v/bon_commande/activeByDevis/" + id  ,{ headers: authHeader() });
}

const getLast = async (callback) => {
  callback( await axios.get(API_URL + "/v/bon_commande/last", { headers: authHeader() }))
}
const getCommandeByProject = async (id) => {
  return await axios.get(API_URL + "/v/bon_commande/byProject/"+id, { headers: authHeader() });
}
const getStatByProjet = async (id) => {
  return await axios.get(API_URL + "/v/bon_commande/statByProjet/", { headers: authHeader() });
}
const exportsMethodes = {
  addBonCommande,
  getBonCommandes,
  getBonCommande,
  updateBonCommande,
  deleteBonCommande,
  changeEtat,
  getActiveReception,
  getLast,
  getActiveByDevis,
  getReference,
  getCommandeByProject,
  getStatByProjet,
  getActiveFacturation,
  getBonCommandesWithoutActiveFacture
};
export default exportsMethodes;