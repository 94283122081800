import React from "react";
import { Card,Result,Button } from "antd";

function NotFound() {
  return (
    <Card style={{ width: "100%" }}>
      <h2 className="text-center">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={<Button type="primary">Back Home</Button>}
        />
      </h2>
    </Card>
  );
}
export default NotFound;
