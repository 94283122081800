import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form, InputNumber,
  message, Radio, Row,
  Select,
  Spin,
  Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import currencys from '../../../helpers/currency';
import { formatterNumber, parserNumber } from "../../../helpers/priceFormater";
import ClientService from "../../../services/client.service";
import DevisService from "../../../services/devis.service";
import EntrepriseService from "../../../services/entreprise.service";
import FactureVenteService from "../../../services/factureVente.service";
import FicheClient from "../../uiHelpers/Fiches/FicheClient";
import FicheDetails from "../../uiHelpers/Fiches/FicheDetails";
import FicheFooter from "../../uiHelpers/Fiches/FicheFooter";
import FicheFournisseur from "../../uiHelpers/Fiches/FicheFournisseur";
import FicheHeader from "../../uiHelpers/Fiches/FicheHeader";
import FicheTableTotauxFactureVente from "../../uiHelpers/Fiches/FicheTableTotauxFactureVente";
import writtenNumber from '/node_modules/written-number/lib/index';
writtenNumber.defaults.lang = 'fr';

function FactureVente() {
  const [productsForm] = Form.useForm();
  const [factureNameForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [fournisseur, setFournisseur] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [entreprise, setEntreprise] = useState({});
  const [isDarkMode, setDarkMode] = useState(false);
  const [appliedTaxes, setListAppliedTaxes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [commandeId, setCommandeId] = useState();
  const [initalQuantite, setInitalQuantite] = useState();
  const [fodec, setFodec] = useState(false);
  const [writtenTotal,setWrittenTotal] = useState("");
  const [selectedCurrency,setSelectedCurrency] = useState("");

  const [devisType, setDevisType] = useState(false);
  const nbrFactures = [ 2 , 3 , 4 ];

  const [ currentEchanceIndex , setCurrentEchanceIndex] = useState(-1); 
  const [ reference , setReference] = useState(""); 


  const history = useHistory();
  const location = useLocation();

  const [prixTotal, setPrixTotal] = useState({
    montant_total: 0,
    total_ttc: 0,
    total_ht: 0,
    fodec: 0,
    acompte : 0,
    tva: 0,
  });

  const isVente = true;

  useEffect(() => {
    loadClients();
    loadEntreprise();
    getThemeMode();
    setUpdate();
    return () => {
      clearStates();
    };
  }, [reference]);

  const setUpdate = async () => {
    const id = location.state?.id;
    setLoading(true);
    if (!location.state?.editMode) {
      await DevisService.getDevis(id, async (response) => {
        if (response.status === 200 || 204) {
          const activeFodec = response.data.fodec > 0 ? true : false;
          setFodec(activeFodec);
          
          const res = response.data;
          res.produit_achats = response.produits_devis;
          const validReception = await loadAllBonReception(id);
          const productList = { ...res };
          productList.produit_achats = productList.produits_devis;
          productList.date = moment(productList.date);
          productList.client = productList.clientId
          if( productList?.echance_devis.length ){
            setFactureEchances(productList, validReception);
          }else {
            setProductsAndQuantites(productList, validReception);
          }


          calculeTotalTaxes(activeFodec);          
          setSelectedCurrency(response.data.devise);
          clientChanged();
          setEditMode(true);
          generateReference();
          
        } else message.error("Une erreur est survenue ! ");
      });
    } else {
      await FactureVenteService.getFacture(id, async (response) => {
        if (response.status === 200 || 204) {
          const activeFodec = response.data.fodec > 0 ? true : false;
          setFodec(activeFodec);
          const productList = response.data;
          productList.produit_achats = productList.produit_facture_ventes;
          productList.client = productList.clientId
          const validReception = await loadAllBonReception(productList.devisId);
          productList.date = moment(productList.date);
          setProductsAndQuantitesUpdate(productList, validReception);
          calculeTotalTaxes(activeFodec);
          setSelectedCurrency(response.data.devise);
          clientChanged();
          setUpdateId(id);
          setEditMode(true);
        } else message.error("Une erreur est survenue ! ");
      });
    }
    setLoading(false);
  };

  const setProductsAndQuantites = (productList, validReception) => {
    setCommandeId(productList.id);
    const lst = [];
    for (const element of productList.produits_devis) {
      if (validReception?.length < 0) {
        element.qtotal = element.quantite;
        element.qRest = element.quantite;
        element.qLivre = 0;
        element.qtotal = element.qLivre + element.qRest;
      } else {
        element.qtotal = element.quantite;
        element.qRest = element.quantite;
        element.qLivre = 0;
        for (const reception of validReception) {
          for (const prod of reception.produit_facture_ventes) {
            if (element.libelle === prod.libelle) {
              element.qLivre += prod.quantite;
              element.qRest -= prod.quantite;
            }
          }
        }
        delete element.id;
        element.quantite = element.qRest;
      }
      if (element.qRest !== 0) lst.push(element);
    }


    productList.produit_achats = lst;
    productsForm.setFieldsValue(productList);
    
  };



  const setFactureEchances = (productList, validReception) => {
    setCommandeId(productList.id);

    const currentEchanceIndex = productList.echance_devis.findIndex( e => e.etat === 0 );
    setCurrentEchanceIndex(currentEchanceIndex)

    const selectedEchance = productList.echance_devis[ currentEchanceIndex ];

    const montantTVA = ( selectedEchance.pourcentage * productList.tva ) / 100   ;
    const prixHT = Number(selectedEchance.montant - montantTVA).toFixed(2);
    const tva = Math.round( ( ( selectedEchance.montant  - prixHT ) * 100 ) / prixHT ); 

    const pList = [];
    const paiementLibelle = getPaiementLibelle( currentEchanceIndex , productList.echance_devis.length, selectedEchance );

    pList.push({
      libelle: paiementLibelle,
      qtotal: 0,
      qRest: 1,
      qLivre: 0,
      quantite: 1,
      tva,
      prixHT,      
      remise: 0,
      total_prix_HT:prixHT,
      total_prix_TTC: selectedEchance.montant
    })
    

    for (const [index, echance] of productList?.echance_devis?.entries()) { 
      if (currentEchanceIndex == index) echance.date = moment(echance.createdAt)
      if (echance.date   ) echance.date = moment(echance.date)
      else echance.date = ""
      if (currentEchanceIndex == index) echance.num_facture = reference  
    }



    productList.produit_achats = pList;
    productsForm.setFieldsValue(productList);

  };

  const getPaiementLibelle = ( index, nbrEchances , echance ) => {
    // if( index === nbrEchances ) return "Facture final "+ Number(echance.pourcentage).toFixed(2) +"% montant " +  Number(echance.montant).toFixed(2)+ "€";
    // if( index === 0 ) return "1er avance "+ Number(echance.pourcentage).toFixed(2) +"% montant " +  Number(echance.montant).toFixed(2) + "€";
    // else return (index + 1 ) + "ème avance "+ Number(echance.pourcentage).toFixed(2) +"% montant " +  Number(echance.montant).toFixed(2) + "€";
    return echance.objet
    
  }

  const setProductsAndQuantitesUpdate = (productList, validReception) => {
    const lst = [];
    for (const element of productList.produit_achats) {
      element.qLivre = 0;
      for (const reception of validReception) {
        for (const product of reception.produit_facture_ventes) {
          if (element.libelle === product.libelle) {
            element.qtotal = product.qtotal;
            element.qLivre += product.quantite;
            element.qRest -= element.quantite;
          }
        }
      }
      setInitalQuantite(element.quantite);
      delete element.id;
      element.qRest = element.qtotal - element.qLivre;
      if (element.qRest > 0);
      lst.push(element);
    }
    productList.produit_achats = lst;
    productsForm.setFieldsValue(productList);
  };

  const generateReference = async () => {
    const year = moment(new Date()).format("YYYY");
    var nbr = "1";
    var reference  = "F" + year + nbr.padStart(4, '0');
    await FactureVenteService.getLast((response) => {
      if (response.data?.reference) {
        const lastRef = response.data.reference;
        const lastFactureYear =  lastRef.substring(1,5);
        if( year === lastFactureYear ){
          nbr = (Number(lastRef.substring(6, 10)) + 1 ) + "";
          reference = "F" + year + nbr.padStart(4, '0');
        }
      }
      setReference(reference)
      productsForm.setFieldsValue({ reference: reference, date: moment(new Date()) });
      setLoading(false);
    });
  };

  const loadAllBonReception = async (id) => {
    setLoading(true);
    const response = await FactureVenteService.getActiveReception(id);
    if (response.status === 200 || 204) {
      return response.data;
    } else message.error("Une erreur est survenue ! 4");
    setLoading(false);
  };

  const addProduct = () => {
    const qte = 1;
    const prixUniteHt = 0;
    const prixUniteTTC = 0
    const tva = 0
    const ht =  0;
    const ttc = 0;
    const remise = 0;
    
    const product = {
      libelle: "",
      quantite: Number(qte),
      prixHT: Number(prixUniteHt),
      prixTTC: Number(prixUniteTTC),
      tva: Number(tva),
      remise: Number(remise),
      total_prix_HT: Number(ht).toFixed(2),
      total_prix_TTC: Number(ttc).toFixed(2),
    };
    
    const productList = productsForm.getFieldValue("produits_achat") ? productsForm.getFieldValue("produits_achat") : [];
    productList.push(product);
    productsForm.setFieldsValue({ produits_achat: productList });
    calculeTotalTaxes();
    
  };

  const getThemeMode = () => {
    const theme = localStorage.getItem("COLOR_THEME");
    const darkMode = theme === "DARK" ? true : false;
    setDarkMode(darkMode);
  };

  const loadClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      setClients(response.data);
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };
  const getClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      return response.data;
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const loadEntreprise = async () => {
    setLoading(true);
    const response = await EntrepriseService.getEntreprise();
    if (response.status === 200 || 204) {
      setEntreprise(response.data);
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const clearStates = () => {
    setEditMode(false);
    setUpdateId(null);
  };

  const priceChanged = (index) => {
    const products = productsForm.getFieldValue("produit_achats");
    const product = products[index];

    const taxe = product.tva;
    const quantite = product.quantite;
    const prixHT = Number( product.prixHT - product.prixHT * (product.remise / 100) ).toFixed(2);
    const prixTTC = (Number(prixHT) + Number((prixHT / 100) * taxe)).toFixed(2);
    const total_ht = Number(prixHT * quantite).toFixed(2);
    const total_ttc = Number(prixTTC * quantite).toFixed(2);

    products[index].total_prix_TTC = total_ttc;
    products[index].total_prix_HT = total_ht;
    productsForm.setFieldsValue({ products });
    calculeTotalTaxes();
  };

  const calculeTotalTaxes = async (activeFodec) => {
    const isFodecActive = activeFodec ? activeFodec : fodec;
    const products = productsForm.getFieldValue("produit_achats");
    const list = [];
    for (const product of products) {
      if (product.tva > 0.1) {
        const base = isFodecActive
          ? Number(product.total_prix_HT) +
            Number(product.total_prix_HT * 1) / 100
          : Number(product.total_prix_HT);
        const montantTaxes = isFodecActive
          ? (
              (Number(product.total_prix_HT) +
                Number(product.total_prix_HT * 0.01)) *
              (product.tva / 100)
            ).toFixed(2)
          : (Number(product.total_prix_HT) * (product.tva / 100)).toFixed(2);
        const taxe = {
          tvaPorucentage: product.tva,
          montantTaxes: montantTaxes,
          base: base,
        };
        list.push(taxe);
      }
    }
    setListAppliedTaxes(list);
    calculateTotal(isFodecActive);
  };
  const clientChanged = async () => {
    const clientId = productsForm.getFieldValue("clientId");
    const clientList = clients.length ? clients : await getClients();
    const selectedClient = clientList.find((client) => client.id === clientId);
    setClient(selectedClient);
  };


  const calculateTotal = (activeFodec) => {
    const isFodecActive = activeFodec ? activeFodec : fodec;
    const factureForm = productsForm.getFieldsValue();
    const remiseGlobal = productsForm.getFieldValue("remise_global") ? productsForm.getFieldValue("remise_global") : 0;

    const products = factureForm.produit_achats;
    let total_ht = 0;
    let tva = 0;
    let total_ttc = 0;
    for (const product of products) {
      const montantTaxes = isFodecActive ? (Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100) : Number(product.total_prix_HT) * (product.tva / 100);
      total_ht += Number(product.total_prix_HT);
      tva += montantTaxes;
    }
    total_ht = Number(total_ht) - ( Number(total_ht) *  (Number(remiseGlobal) / 100));
    tva =  tva - ( Number(tva) *  (Number(remiseGlobal) / 100));

    let fodecMontant = isFodecActive ? Number(total_ht) / 100 : 0;
    total_ttc += Number(total_ht) + Number(tva) + Number(fodecMontant);
    let montant_total = Number(total_ttc).toFixed(2);
    const total = {
      total_ht,
      tva,
      total_ttc,
      fodec: fodecMontant,
      montant_total,
    };
    setPrixTotal(total);
    const final = montant_total.toString().split(".");
    const currencyName = currencys.find(c => c.code === productsForm.getFieldValue("devise"));
    setWrittenTotal("Le compte est arrêté à la somme de " + writtenNumber(final[0]) + " " +currencyName.base + " , " +final[1] + " " + currencyName.sub);

  };



  const addFacture = async () => {
    try{
      setLoading(true);
      const product = productsForm.getFieldsValue();
      const factureVente = { ...product, ...prixTotal };
      factureVente.etat = 0;
      factureVente.produit_facture_ventes = factureVente.produit_achats;
      delete factureVente.produit_achats;
      factureVente.bonCommandeId = commandeId;
      const updateId = location.state.id;

      factureVente.typeFacture = factureVente.echance_devis?.length > 0 ? 2 : 1;
      if( factureVente.typeFacture === 2 ){
        factureVente.echance_devis[currentEchanceIndex].datePaiement =  moment(new Date());
        factureVente.echance_devis[currentEchanceIndex].etat = 1;
      }

      !location.state?.editMode
        ? await FactureVenteService.addFacture(factureVente)
        : await FactureVenteService.updateFacture(updateId, factureVente);
      
      location.state?.editMode 
        ? message.info("Facture Mise a jour Avec Success")
        : message.info("Facture Ajouté Avec Success");
        
      history.push("/facture_vente");
      setLoading(false);
      
    } catch ( err ){

      console.log( err );
      message.error("Une erreur est survenue ! ");
      setLoading(false);

    }
  };

  const getMax = (index) => {
    const rest = productsForm.getFieldValue("produit_achats")[index]?.qRest;
    const max = location.state?.editMode
      ? Number(rest) + Number(initalQuantite)
      : rest;
    return max;
  };


  const changeCurrency = () => {
    const currency = productsForm.getFieldValue("devise");
    setSelectedCurrency(currency);
  }

  const pourcentageChanged = () => {
  }

  const nbrFacturesChanged = () => {
  }
  
  const montantChanged = () => {
  }

  return (
    <>
      <div className="px-0">
        <Spin spinning={isLoading} size="large">
          <Card className="w-100 card-shadow">
            <Form
              layout="vertical"
              form={productsForm}
              onFinish={addFacture}
            >
              <Row>
                <FicheHeader
                  moduleName={"Facture"}
                  showClients={isVente}
                  entreprise={entreprise}
                  clients={clients}
                  fournisseurs={fournisseurs}
                  selectedClient={(client) => setClient(client)}
                ></FicheHeader>
              </Row>
              <Row>
                {isVente ? (
                  <Col span={8} className="px-2 pt-3">
                    <FicheClient client={client}></FicheClient>
                  </Col>
                ) : (
                  <Col span={8} className="px-2 pt-3">
                    <FicheFournisseur
                      fournisseur={fournisseur}
                    ></FicheFournisseur>
                  </Col>
                )}
                <Col span={8} offset={8} className="px-2 pt-3">
                  <FicheDetails
                    currencyChanged={ ()=>  changeCurrency()} 
                  ></FicheDetails>
                </Col>
              </Row>
              <Row>
                <Col span="24" className="px-4">
                  <div
                    className={` mb-5 table table-like ${
                      isDarkMode ? "table-dark" : ""
                    } `}
                  >
                    <div className="pl-2 thead-like">
                      <Row>
                        <Col span={1}>
                          <b>#</b>
                        </Col>
                        <Col span={6}>
                          <b>Libelle</b>
                        </Col>
                        {/* <Col span={1}>
                          <Tooltip title="Quantité Total">
                            <b>QT</b>
                          </Tooltip>
                        </Col>

                        <Col span={1}>
                          <Tooltip title="Quantité Livré">
                            <b>QL</b>
                          </Tooltip>
                        </Col>

                        <Col span={1}>
                          <Tooltip title="Quantité Reste à Livré">
                            <b>Q.R.L</b>
                          </Tooltip>
                        </Col> */}

                        <Col span={2}>
                          <b>Quantité</b>
                        </Col>

                        <Col span={2}>
                          <Tooltip title="Prix Unitaire HT">
                            <b>Prix HT</b>
                          </Tooltip>
                        </Col>

                        <Col span={2}>
                          <b>TVA</b>
                        </Col>

                        <Col span={2}>
                          <b>Remise</b>
                        </Col>

                        <Col span={2}>
                          <b>Total HT</b>
                        </Col>

                        <Col span={2}>
                          <b>Total TTC</b>
                        </Col>

                        <Col className="px-3" span={2}>
                          <Tooltip title="Ajouter un produit">
                            <Button
                              shape="circle"
                              onClick={() => addProduct()}
                              icon={<PlusCircleOutlined />}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Form.List name="produit_achats" label="">
                        {(fields, { add, remove }) => (
                          <>
                            <Row className="pb-1 mb-1 ">
                              {fields.map(
                                ({ key, name, ...restField }, index) => (
                                  <>
                                    <Col className="mb-0 pb-0" span={1}>
                                      {index + 1}
                                    </Col>
                                    <Col className="mb-0 pb-0" span={6}>
                                      <Form.Item
                                      {...restField}
                                      name={[name, "libelle"]}
                                      rules={[
                                        {
                                          required: true,
                                          message:"Champ obligatoire !",
                                        }
                                      ]}
                                    >
                                      <TextArea style={{ width: "100%" }} rows={2} className="mx-0" type="text" />
                                  </Form.Item>
                                    </Col>
                                    {/* <Col className="mb-0 pb-0" span={1}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "qtotal"]}
                                      >
                                        <InputNumber
                                          className={`${
                                            isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                          } `}
                                          disabled
                                          style={{ width: 50 }}
                                          bordered={false}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={1}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "qLivre"]}
                                      >
                                        <InputNumber
                                          className={`${
                                            isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                          } `}
                                          disabled
                                          style={{ width: 50 }}
                                          bordered={false}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={1}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "qRest"]}
                                      >
                                        <InputNumber
                                          className={`${
                                            isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                          } `}
                                          disabled
                                          bordered={false}
                                          style={{ width: 50 }}
                                        />
                                      </Form.Item>
                                    </Col> */}
                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "quantite"]}
                                      >
                                        <InputNumber
                                          style={{ width: 100 }}
                                          onChange={() => priceChanged(index)}
                                          className="w-125px"
                                          min={0}
                                          max={getMax(index)}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "prixHT"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Champ obligatoire avec un maximum de 100 caractères  !",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          className={`w-125px ${
                                            isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                          } `}
                                          disabled
                                          bordered={false}
                                          onChange={() => priceChanged(index)}
                                          style={{ width: 120 }}
                                          min={0}
                                          formatter={(value) => formatterNumber(value)}
                                          parser={(value) => parserNumber(value)}
                                          // formatter={(value) =>
                                          //   `${value}`
                                          // }
                                          // parser={(value) =>
                                          //   value.replace("", "")
                                          // }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "tva"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Champ obligatoire avec un maximum de 100 caractères  !",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          className={`w-125px ${
                                            isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                          } `}
                                          disabled
                                          bordered={false}
                                          onChange={() => priceChanged(index)}
                                          style={{ width: 60 }}
                                          min={0}
                                          max={100}
                                          formatter={(value) => `${value}%`}
                                          parser={(value) =>
                                            value.replace("%", "")
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "remise"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Champ obligatoire avec un maximum de 100 caractères  !",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          className={`w-125px ${
                                            isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                          } `}
                                          disabled
                                          bordered={false}
                                          onChange={() => priceChanged(index)}
                                          min={0}
                                          max={100}
                                          style={{ width: 60 }}
                                          formatter={(value) => `${value}%`}
                                          parser={(value) =>
                                            value.replace("%", "")
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "total_prix_HT"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Champ obligatoire avec un maximum de 100 caractères  !",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          className={` ${
                                            isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                          } `}
                                          disabled
                                          bordered={false}
                                          style={{ width: 120 }}
                                          min={0}
                                          formatter={(value) => formatterNumber(value)}
                                          parser={(value) => parserNumber(value)}
                                          // formatter={(value) =>
                                          //   `${value}`
                                          // }
                                          // parser={(value) =>
                                          //   value.replace("", "")
                                          // }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "total_prix_TTC"]}
                                      >
                                        <InputNumber
                                          className={`w-125px ${
                                            isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                          } `}
                                          disabled
                                          bordered={false}
                                          style={{ width: 120 }}
                                          min={0}
                                          formatter={(value) => formatterNumber(value)}
                                          parser={(value) => parserNumber(value)}
                                          // formatter={(value) =>
                                          //   `${value}`
                                          // }
                                          // parser={(value) =>
                                          //   value.replace("", "")
                                          // }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0 px-3" span={2}>
                                      <Tooltip title="Supprimer le produit">
                                        <Button
                                          shape="circle"
                                          icon={<DeleteOutlined />}
                                          onClick={() => { remove(name); calculeTotalTaxes(); }}
                                        />
                                      </Tooltip>
                                    </Col>
                                  </>
                                )
                              )}
                            </Row>
                          </>
                        )}
                      </Form.List>
                    </div>
                  </div>
                </Col>
              
              <Col span={15} className="px-4">
                <Row>
                  { productsForm.getFieldValue("echance_devis")?.length > 0 && 
                    <>
                      <Col span={2} className="py-2 text-center">
                        Num
                      </Col>
                      <Col span={4} className="py-2 pr-02 pl-02">
                        Pourcentage
                      </Col>
                      <Col span={4} className="py-2  pr-02 pl-02">
                        Montant TTC
                      </Col>
                      <Col span={5} className="py-2  pr-02 pl-02">
                        Date
                      </Col>
                      <Col span={5} className="py-2  pr-02 pl-02">
                        Numéro Facture
                      </Col>
                      <Col span={4} className="py-2 text-center">
                        Etat
                      </Col>
                    </>
                  }
                </Row>

                <Row>
                  <Form.List
                    name="echance_devis"
                    label=""
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <>
                            <Col span={2} className="pr-02 pt-1 text-center">
                              <b> { key + 1 } </b>
                            </Col>
                            <Col span={4} className="pr-02 pl-02">
                              <Form.Item
                                {...restField}
                                name={[name, "pourcentage"]}
                                
                                rules={[
                                  {
                                    required: true,
                                    message:"Champ obligatoire !",
                                  }
                                ]}
                              >
                                <InputNumber
                                  disabled
                                  onChange={() => pourcentageChanged(index)}
                                  className="w-125px"
                                  min={0}
                                  showCount
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4} className="pl-02">
                              <Form.Item
                                {...restField}
                                name={[name, "montant"]}
                                rules={[{
                                  required: true,
                                  message: "Champ obligatoire!",
                                }]}
                              >
                                <InputNumber
                                  onChange={() => montantChanged(index)}
                                  className="w-125px"
                                  min={0}
                                  showCount
                                  disabled
                                  style={{ width: "100%" }}
                                  formatter={(value) => formatterNumber(value)}
                                  parser={(value) => parserNumber(value)}
                                />
                              </Form.Item>
                            </Col>

                            <Col span={5} className="pl-02">
                              <Form.Item
                                {...restField}
                                name={[name, "date"]}
                                rules={[{}]}
                              >
                                <DatePicker disabled placeholder=" " className="w-100" format={"DD-MM-YYYY"}></DatePicker>
                              </Form.Item>
                            </Col>

                            
                            <Col span={5} className="pl-02">
                              <Form.Item
                                {...restField}
                                name={[name, "num_facture"]}
                                rules={[{
                                  required: true,
                                  message: "Champ obligatoire!",
                                }]}
                              >
                                <InputNumber disabled placeholder=" " className="w-100" format={"DD-MM-YYYY"} />
                              </Form.Item>
                            </Col>

                            
                            <Col span={4} className="pt-1 text-center">
                              <b> { productsForm.getFieldValue("echance_devis")[index]?.etat === 0 ? "Non Payé" : "Payé"} </b>
                            </Col>
                              
                          </>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Row>
              </Col>
              <Col span={8} className="px-4">
                <Form.Item  label="Remise" initialValue={0} name="remise_global" className="my-0 py-1">
                  <InputNumber onChange={() => calculateTotal()} style={{ width: "100%" }}
                    formatter={(value) => `${value}%`} parser={(value) => value.replace("%", "")}
                    min={0} max={100}
                  />
                </Form.Item>
                <FicheTableTotauxFactureVente
                  fodec={fodec}
                  selectedCurrency={selectedCurrency}
                  isDarkMode={isDarkMode}
                  prixTotal={prixTotal}
                ></FicheTableTotauxFactureVente>
              </Col>
              
                <Col span="8"></Col>
                <Col span="8" className="px-4"></Col> 
              </Row>
              <Divider className="text-capitalize">{writtenTotal}</Divider>

              <FicheFooter entreprise={entreprise}></FicheFooter>

              <Row className="d-flex flex-row-reverse pt-5 pb-2">
                <Button
                  className="mx-2"
                  type="primary"
                  onClick={() => addFacture() }
                >
                  Enregistrer
                </Button>
                
              </Row>
            </Form>
          </Card>
        </Spin>
      </div>
    </>
  );
}

export default FactureVente;



