import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  message,
  Modal, Row,
  Select,
  Spin,
  Switch, Tabs,
  Tooltip
} from "antd";
import React, { useEffect, useState } from "react";
import country from "../../../helpers/country";
import ClientService from "../../../services/client.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFilters from "../../uiHelpers/TableFilters";

const Client = () => {
  const [clientForm] = Form.useForm();
  const [clientModal, setClientModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [switchValue, setSwitchValue] = useState("unchecked");
  const [isEntrprise, setClientType] = useState(false);

  const [dataTable, setDataTable] = useState([]);
  const [advencedTabelData, setAdvencedTableData] = useState([]);
  const [simpleTable,setSimpleTable] = useState(true);
  const [userType, setUserType] = useState(true);
  const [finalTabKey, setFinalTabKey] = useState(1);

  const simpleClientColumns = [
    {
      title: "Type",
      dataIndex: "showType",
      sorter: (a, b) => a?.showType.localeCompare(b?.showType),
    },
    {
      title: "Nom",
      dataIndex: "showNom",
      sorter: (a, b) => a?.showNom.localeCompare(b?.showNom),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      sorter: (a, b) => a?.email.localeCompare(b?.email),
    },
    {
      title: "Téléphone ",
      dataIndex: "telephone_pro",
      sorter: (a, b) => a?.telephone_pro - b?.telephone_pro,
    },{
      title: "Code postale",
      dataIndex: "code_postale",
      sorter: (a, b) => a?.code_postale - b?.code_postale,
    },
    {
      title:"Action",
      key: "action",
      width: "15%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled
              onClick={() => confirmDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    loadClients();
    getUserIdentity();
    return () => {
      clearStates();
    };
  }, [isEntrprise]);

  const getUserIdentity = ()=> {
    setUserType(Number(localStorage.getItem('Identity')))
  }

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    // setUpdateId(null);
  };
  const loadClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const initTableData = (clients) => {
    for (const client of clients) {
      client.showType = client.type ? "Entreprise" : "Particulier";
      client.showNom = client.showType ===  "Particulier" ? client.nom + " " + client.prenom : client.raison_sociale ;
      // client.showNom = client.nom + " " + client.prenom
    }
    setDataTable(clients);
  };

  const clientFactures = async () => {
    if( !advencedTabelData.length ){
      const list = dataTable;
      for (const element of list) {
        const response = await ClientService.getClientFacture(element.id);
        const data = response.data;
        for (const stat of data) {
          element.notPayed = stat.paiement_status === 0 ? Number(stat.total).toFixed(2) : "0.000";
          element.payed = stat.paiement_status === 1 ? Number(stat.total).toFixed(2) : "0.000";
          element.enGoing =  stat.paiement_status === 2 ? Number(stat.total).toFixed(2) : "0.000";
        }
      }
      setAdvencedTableData(list);
    }

    setSimpleTable(false);
  };
  const showClientModal = () => {
    setClientModal(true);
  };

  const handleOk = () => {
    setClientModal(false);
  };

  const updateMode = async (record) => {
    setClientModal(true);
    const checkedSwitch = record.type ? "checked" : "unchecked";
    if (record.type == 1) setClientType(true)
    setSwitchValue(checkedSwitch);
    clientForm.setFieldsValue(record);
    await setUpdateId(record.id);
    await setEditMode(true);
  };

  const handleCancel = () => {
    setClientModal(false);
    clearData();
    setClientType(false)
    setFinalTabKey(1)
  };

  const addClient = async (values) => {
    var data = values;
    data.type = data.type ? data.type : false;
    const response = editMode
      ? await ClientService.updateClient(updateId, values)
      : await ClientService.addClient(values);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour succès");
      else message.info("Client ajouter succès");
    } else message.error("Une erreur est survenue ! ");

    closingModal();
  };

  const closingModal = () => {
    loadClients();
    setClientModal(false);
    setLoading(false);
    setSwitchValue("unchecked");
    setClientType(false);
    clearData();
  };

  const clearData = () => {
    clientForm.resetFields();
    clientForm.setFieldsValue({ type: false });
    setEditMode(false);
    setUpdateId(null);
    setClientType(false);
  };

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce Client ?",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };
  const deleteItem = async (item) => {
    const response = await ClientService.deleteClient(item.id);
    if (response.status === 204 || 200) {
      message.info("Suppression succès");
      loadClients();
    } else message.error("Une erreur est survenue ! ");
  };
  const switchChange = () => {
    setClientType(clientForm.getFieldValue("type"));
  };
  const locale = {
    emptyText: (
      <Empty description={"Le tableau est vide pour le moment "}></Empty>
    ),
  };

  const handleTabsChange = (key) => {
    isNaN(key) ? setFinalTabKey(2) : setFinalTabKey(key) 
  }

  return (
    <>
      { userType === 2 ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Clients"}
            extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={() => showClientModal()}
              >
                Ajouter un client
              </Button>
            }
          >
            <>
              <TableWithFilters
                showSorterTooltip={false}
                rowKey={(record) => record.id}
                locale={locale}
                columns={simpleClientColumns}
                data={dataTable}
                pagination={{ position: ["bottomCenter"], pageSize: 5 }}
              />
            </>

            <Form layout="vertical" form={clientForm} onFinish={addClient}
              initialValues={{ pays: "Luxembourg", code_phone_pro: "+352"  }}
            >
              <Modal
                visible={clientModal}
                onOk={finalTabKey == 2 ? clientForm.submit : handleTabsChange}
                onCancel={handleCancel}
                getContainer={false}
                okText={finalTabKey == 2 ? "Enregistrer" : "Suivant"}
                cancelText="Annuler"
                centered
                width={1100}
                title={
                  <>
                    <div className="d-flex justify-content-between ">
                      <div className="my-0">
                        {editMode ? "Modifier le client" : "Ajouter un client"}
                      </div>
                      <div>
                        <Form.Item
                          className="my-0 mx-5"
                          name="type"
                          valuePropName={switchValue}
                        >
                          <Switch
                            checkedChildren="Entreprise"
                            unCheckedChildren="Particulier"
                            className="px-4"
                            onChange={() => switchChange()}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                }
              >
                <Tabs activeKey={`${finalTabKey}`} onChange={handleTabsChange}>
                  <Tabs.TabPane tab="Informations Générales" key="1">
                    <Row>
                      {isEntrprise && (
                      <>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="Nom de l'entreprise"
                            name="raison_sociale"
                            rules={[
                              {
                                message:"Maximum 100 caractères  !",
                                max: 99,
                              },
                            ]}
                          >
                            <Input size="large" type="text" maxLength={100} />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                          <Form.Item
                            label="TVA"
                            name="matricule_fiscal"
                            rules={[
                              {
                                message:"Maximum 100 caractères  !",
                                max: 99,
                              },
                            ]}
                          >
                            <Input size="large" type="text" maxLength={100} />
                          </Form.Item>
                        </Col>
                      </>
                      )}
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Nom"
                          name="nom"
                          rules={[
                            {max :100 ,message:"Maximum 100 caractères  !",},
                            {pattern:/^([a-zA-Z]+\s)*[a-zA-Z]+$/ , message : "Format invalid !" },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Prénom"
                          name="prenom"
                          rules={[
                            {max :100 ,message:"Maximum 100 caractères  !" },
                            {pattern:/^([a-zA-Z]+\s)*[a-zA-Z]+$/ , message : "Format invalid !" },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="E-mail"
                          name="email"
                          rules={[
                            {
                              type: 'email',
                              message: 'Format invalid!',
                            },{
                              required : true,
                              message: "Champ obligatoire !",
                            }
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item 
                          rules={[
                            {
                              pattern:/^((http|https):\/\/.)|(www.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g,
                              message: 'Format invalid!',
                            },
                          ]}
                          label="Site Web" 
                          name="site">
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={4} className="px-2">
                        <Form.Item
                          label="Code Tél"
                          name="code_phone_pro"
                          rules={[
                            {
                              max: 99,
                              message: "Maximum 100 caractères  !",
                            },
                          ]}
                        >
                          <Select size="large">
                            {country &&
                              country.map((c) => (
                                <Select.Option
                                  key={c.id}
                                  value={c.telCode}
                                >
                                  {c.telCode}  {c.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Numéro Téléphone "
                          name="telephone_pro"
                          tooltip="Maximum 13 chiffres"
                        >
                          <Input
                            size="large"
                            style={{ width: "100%" }}
                            min={0}
                            max={99999999999999}
                          />
                        </Form.Item>
                      </Col>
                      
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Adresses client" key="2" forceRender={true}>
                    <b className="px-2">Adresse :</b> <br />
                    <Row>
                      <Col span={4} className="px-2 mt-2">
                        <Form.Item
                          label="Pays"
                          name="pays"
                          rules={[
                            {
                              required: true,
                              message: "Champ obligatoire !",
                            },
                          ]}
                        >
                          <Select size="large" >
                            {country.map((c) => (
                              <Select.Option  key={c.id} value={c.name}>
                                {c.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4} className="px-2 mt-2">
                        <Form.Item
                          label="Ville"
                          name="ville"
                          rules={[
                            {
                              message: "Maximum 100 caractères  !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="px-2 mt-2">
                        <Form.Item
                          label="Adresse"
                          name="adresse"
                          rules={[
                            {
                              message: "Maximum 100 caractères  !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>

                      <Col span={4} className="px-2 mt-2">
                        <Form.Item
                          label="Numéro"
                          name="num_adresse"
                          rules={[
                            {
                              message: "Maximum 100 caractères  !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>

                      <Col span={4} className="px-2 mt-2">
                        <Form.Item
                          label="Code postale"
                          name="code_postale"
                          rules={[
                            {
                              message: "Maximum 100 caractères  !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <b className="p-2">Autres :</b>
                      </Col>

                      <Form.List
                        name="adresse_clients"
                        label="List des addresse de livraisons"
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <>
                                <Col span={4} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "pays"]}
                                    label="Pays"
                                    initialValues={"Luxembourg"}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Champ obligatoire avec un maximum de 100 caractères  !",
                                      },
                                    ]}
                                  >
                                    <Select size="large">
                                      {country.map((c) => (
                                        <Select.Option key={c.id} value={c.name}>
                                          {c.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={4} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "ville"]}
                                    label="Ville"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec un maximum de 100 caractères  !",
                                        max: 99,
                                      },
                                    ]}
                                  >
                                    <Input size="large" />
                                  </Form.Item>
                                </Col>
                                <Col span={8} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "adresse"]}
                                    label="Adresse"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec un maximum de 100 caractères  !",
                                        max: 99,
                                      },
                                    ]}
                                  >
                                    <Input size="large" />
                                  </Form.Item>
                                </Col>

                                <Col span={4} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "num_adresse"]}
                                    label="Numéro"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec un maximum de 100 caractères  !",
                                        max: 99,
                                      },
                                    ]}
                                  >
                                    <Input size="large" />
                                  </Form.Item>
                                </Col>

                                <Col span={3} className="px-2 mt-2">
                                  <Form.Item
                                    name={[name, "code_postale"]}
                                    label="Code postale"
                                    rules={[
                                      {
                                        message: "Champ obligatoire avec un maximum de 100 caractères  !",
                                        max: 99,
                                      },
                                    ]}
                                  >
                                    <Input size="large" />
                                  </Form.Item>
                                </Col>
                                <Col span={1} className="px-2 mt-2">
                                  <Form.Item label=" ">
                                    <MinusCircleOutlined
                                      size="large"
                                      onClick={() => remove(name)}
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            ))}
                            <Row>
                              <Col span={12} className="px-2 mt-3">
                                <Button type="dashed" onClick={() => add()}>
                                  Autre Adresse
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Form.List>
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </Modal>
            </Form>
          </Card>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  )
};
export default Client;
